<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Back to Resources" 
      :showGetMoreResourcesButton="true"
      @back="goBackToHome"
      @getMoreResources="goToResourcesPage"
    />
    <div class="content">
      <h1>Create a Topic</h1>
      <div class="create-topic-container">
        <input v-model="newTopic" type="text" placeholder="Write a topic" maxlength="75" />
        <button @click="createTopic" class="create-button">Create</button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>
      <h2>Search For Topics</h2>
      <input v-model="searchQuery" type="text" placeholder="Search for a topic" />
      <ul class="topics-list">
        <li v-for="topic in filteredTopics" :key="topic._id">
          <router-link :to="{ name: 'suggestions', params: { topicName: topic.name } }">{{ topic.name }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import axiosInstance from '@/axiosConfig';

export default {
  components: { Header },
  data() {
    return {
      newTopic: '',
      topics: [],  // Stores all topics loaded from the backend
      searchQuery: '',
      errorMessage: '',
    };
  },
  computed: {
    filteredTopics() {
      const query = this.searchQuery.toLowerCase().trim();
      return this.topics.filter(topic => topic.name.toLowerCase().includes(query));
    }
  },
  methods: {
    async createTopic() {
      if (this.newTopic.trim() === '') {
        this.errorMessage = 'Topic name cannot be empty.';
        return;
      }

      try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
          this.errorMessage = 'You need to login to create a topic.';
          return;
        }

        const response = await axiosInstance.post('/topics', { name: this.newTopic.trim() }, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data && response.data.topic) {
          this.topics.push(response.data.topic);
        }

        this.newTopic = '';
        this.errorMessage = '';
      } catch (error) {
        if (error.response && error.response.data.message) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = 'Failed to create topic.';
        }
      }
    },
    async loadTopics() {
      try {
        const response = await axiosInstance.get('/topics');
        this.topics = Array.isArray(response.data) ? response.data : [];
      } catch (error) {
        console.error('Failed to load topics:', error);
        this.errorMessage = 'Failed to load topics.';
        this.topics = [];
      }
    },
    goBackToHome() {
      this.$router.push('/home');
    },
    goToResourcesPage() {
      this.$router.push('/resources');
    }
  },
  async created() {
    await this.loadTopics();
  },
  watch: {
    $route() {
      this.loadTopics();
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 60px;
  padding: 20px;
  overflow-y: auto;
}

.create-topic-container {
  width: 100%;
  margin-bottom: 20px;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.create-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
}

.create-button:hover {
  background-color: #555;
}

ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.error-message {
  color: red;
}
</style>

<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      :backText="backText"
      :showLogoutButton="true"
      @back="goBackToUser"
    />
    <div class="content">
      <h1>Followers of {{ username }}</h1>
      <ul class="user-list">
        <li v-for="follower in followers" :key="follower.follower">
          <router-link :to="{ name: 'user-details', params: { username: follower.follower, previousTopic: previousTopic, previousUser: username } }">
            {{ follower.follower }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/axiosConfig';
import Header from './Header.vue';

export default {
  components: {
    Header,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    previousTopic: {
      type: String,
      required: true,
    },
    previousUser: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      followers: [],
    };
  },
  computed: {
    backText() {
      return this.previousUser ? `Back to ${this.previousUser}` : "Back to User";
    }
  },
  async created() {
    await this.loadFollowers();
  },
  methods: {
    async loadFollowers() {
      try {
        const response = await axiosInstance.get(`/followers/${this.username}`);
        this.followers = response.data;
      } catch (error) {
        console.error('Failed to load followers:', error);
      }
    },
    goBackToUser() {
      this.$router.push({
        name: 'user-details',
        params: { username: this.previousUser || this.username, previousTopic: this.previousTopic }
      });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  margin-top: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

.user-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.user-list li {
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.user-list li:hover {
  background-color: #f0f0f0;
}
</style>

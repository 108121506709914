<template>
  <div class="container">
    <Header :showBackButton="true" backText="Back to Login" @back="goToLogin" />
    <div class="content">
      <h1>What are your resources?</h1>
      <div class="textarea-container">
        <textarea 
          v-model="resource" 
          placeholder="Write Your Resources, e.g. Skills, Values, Career, etc."
          maxlength="345">
        </textarea>
        <button @click="submitResource" class="submit-button">Submit</button>
      </div>
      <p v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import axiosInstance from '@/axiosConfig';

export default {
  components: {
    Header
  },
  data() {
    return {
      resource: '',
      error: ''
    };
  },
  mounted() {
    const savedResource = localStorage.getItem('resource');
    if (savedResource) {
      this.resource = savedResource;
    }
  },
  methods: {
    goToLogin() {
      this.$router.push('/');
    },
    async submitResource() {
      if (this.resource.trim() === '') {
        this.error = 'Resource cannot be empty!';
        return;
      }

      try {
        const token = localStorage.getItem('jwtToken');  // Ensure token is retrieved correctly
        if (!token) {
          this.error = 'User not authenticated!';
          this.$router.push('/'); // Redirect to login page if no token
          return;
        }

        const response = await axiosInstance.post('/resource', {
          resource: this.resource.trim(),
        }, {
          headers: {
            'Authorization': `Bearer ${token}`  // Include token in headers
          }
        });

        if (response.data.success) {
          localStorage.setItem('resource', this.resource.trim());
          this.$router.push('/topics');  // Redirect to topics page
        } else {
          this.error = 'Failed to save resource. Please try again.';
        }
      } catch (error) {
        console.error('Failed to save resource:', error);
        if (error.response && error.response.status === 403) {
          this.error = 'Authentication failed. Please log in again.';
          localStorage.removeItem('jwtToken');  // Remove expired token
          this.$router.push('/');  // Redirect to login
        } else {
          this.error = 'Failed to save resource. Please try again later.';
        }
      }
    }
  }
};
</script>

<style scoped>
html, body {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  overflow: hidden;
}

#app {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.textarea-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 600px;
}

textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.submit-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
}

.submit-button:hover {
  background-color: #555;
}
</style>

// router.js
import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/components/LoginPage.vue';
import HomePage from '@/components/HomePage.vue';
import TopicsPage from '@/components/TopicsPage.vue';
import SuggestionsPage from '@/components/SuggestionsPage.vue';
import AboutResuvoPage from '@/components/AboutResuvoPage.vue';
import FollowersPage from '@/components/FollowersPage.vue';
import FollowingPage from '@/components/FollowingPage.vue';
import UserTopicSuggestionsPage from '@/components/UserTopicSuggestionsPage.vue';
import PrivateTopicsPage from '@/components/PrivateTopicsPage.vue';
import PrivateSuggestionsPage from '@/components/PrivateSuggestionsPage.vue';
import ResourcesPage from '@/components/ResourcesPage.vue';
import BooksPage from '@/components/BooksPage.vue';
import BookDetails from '@/components/BookDetails.vue';
import ArticlesPage from '@/components/ArticlesPage.vue';
import ArticleDetails from '@/components/ArticleDetails.vue';

const routes = [
  { path: '/', name: 'login', component: LoginPage },
  { path: '/home', name: 'home', component: HomePage },
  { path: '/topics', name: 'topics', component: TopicsPage },
  {
    path: '/suggestions/:topicName',
    name: 'suggestions',
    component: SuggestionsPage,
    props: true,
  },
  {
    path: '/about-resuvo',
    name: 'about-resuvo',
    component: AboutResuvoPage,
  },
  {
    path: '/followers/:username',
    name: 'followers',
    component: FollowersPage,
    props: true,
  },
  {
    path: '/following/:username',
    name: 'following',
    component: FollowingPage,
    props: true,
  },
  {
    path: '/user-topic-suggestions/:username/:topicName',
    name: 'user-topic-suggestions',
    component: UserTopicSuggestionsPage,
    props: true,
  },
  {
    path: '/private-topics',
    name: 'private-topics',
    component: PrivateTopicsPage,
  },
  {
    path: '/private-suggestions/:topicName',
    name: 'private-suggestions',
    component: PrivateSuggestionsPage,
    props: true,
  },
  {
    path: '/resources',
    name: 'resources',
    component: ResourcesPage,
  },
  {
    path: '/books',
    name: 'books',
    component: BooksPage,
  },
  {
    path: '/books/:bookId',
    name: 'book-details',
    component: BookDetails,
    props: true,
  },
  {
    path: '/articles',
    name: 'articles',
    component: ArticlesPage,
  },
  {
    path: '/articles/:articleId',
    name: 'article-details',
    component: ArticleDetails,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

import { createStore } from 'vuex';

export default createStore({
  state: {
    scrollPositions: {}, // Stores scroll positions for each page
    articleSearchQuery: '', // For ArticlesPage search query
    bookSearchQuery: '', // For BooksPage search query
    selectedBookGenre: '', // To store the selected genre in BooksPage
  },
  getters: {
    getScrollPosition: (state) => (pageId) => {
      return state.scrollPositions[pageId] || 0;
    },
    getArticleSearchQuery: (state) => {
      return state.articleSearchQuery;
    },
    getBookSearchQuery: (state) => {
      return state.bookSearchQuery;
    },
    getSelectedBookGenre: (state) => {
      return state.selectedBookGenre;
    },
  },
  mutations: {
    setScrollPosition(state, { pageId, position }) {
      state.scrollPositions[pageId] = position;
    },
    setArticleSearchQuery(state, query) {
      state.articleSearchQuery = query;
    },
    setBookSearchQuery(state, query) {
      state.bookSearchQuery = query;
    },
    setSelectedBookGenre(state, genre) {
      state.selectedBookGenre = genre;
    },
  },
  actions: {
    saveScrollPosition({ commit }, payload) {
      commit('setScrollPosition', payload);
    },
    saveArticleSearchQuery({ commit }, query) {
      commit('setArticleSearchQuery', query);
    },
    saveBookSearchQuery({ commit }, query) {
      commit('setBookSearchQuery', query);
    },
    saveSelectedBookGenre({ commit }, genre) {
      commit('setSelectedBookGenre', genre);
    },
  },
});

<template>
  <div class="header">
    <div class="left">
      <button v-if="showBackButton" @click="emitBack" class="back-button">{{ backText }}</button>
      <button v-if="showGetMoreResourcesButton" @click="goToResources" class="get-resources-button">Get More Resources</button>
    </div>
    <div class="center">
      <router-link to="/">
        <img src="/profile-picture.jpg" class="logo" alt="Logo">
      </router-link>
    </div>
    <div class="right">
      <button v-if="isTopicsPage" @click="goToPrivateTopics" class="private-button">Private Topics</button>
      <button v-if="showLogoutButton && !isAboutResuvoPage" @click="goToAboutResuvo" class="about-button">About Resuvonia</button>
      <button v-if="showLogoutButton" @click="logout" class="logout-button">Logout</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showBackButton: { type: Boolean, default: false },
    backText: { type: String, default: "Back" },
    showLogoutButton: { type: Boolean, default: true },
    showGetMoreResourcesButton: { type: Boolean, default: false },
  },
  computed: {
    isTopicsPage() {
      return this.$route.name === 'topics';
    },
    isAboutResuvoPage() {
      return this.$route.name === 'about-resuvo';
    },
  },
  methods: {
    emitBack() {
      this.$emit('back');
    },
    logout() {
      localStorage.removeItem('currentUser');
      this.$router.push('/');
    },
    goToAboutResuvo() {
      this.$router.push('/about-resuvo');
    },
    goToPrivateTopics() {
      this.$router.push({ name: 'private-topics' });
    },
    goToResources() {
      this.$router.push('/resources');
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 10px;
  width: 100%;
  position: fixed; /* Keeps the header fixed */
  top: 0;
  left: 0;
  z-index: 1000;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.left, .right {
  display: flex;
  align-items: center;
}

.left {
  position: absolute;
  left: 20px;
}

.right {
  position: absolute;
  right: 20px;
}

.center {
  margin: 0 auto;
}

.logo {
  height: 50px;
  cursor: pointer;
}

.back-button,
.logout-button,
.about-button,
.private-button,
.get-resources-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin-right: 10px;
}

.back-button:hover,
.logout-button:hover,
.about-button:hover,
.private-button:hover,
.get-resources-button:hover {
  background-color: #555;
}
</style>

<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Back to the User" 
      :showLogoutButton="true" 
      :showGetMoreVotesButton="true" 
      @back="goBackToUser" 
      @navigateToVotesPage="navigateToVotesPage"
    />
    <div class="content">
      <h1 class="topic-title">Suggestions by {{ username }} for {{ formattedTopicName }}</h1>
      <div class="sorting-buttons">
        <button @click="sortByMostRecent">Most Recent</button>
        <button @click="sortByMostVoted">Most Voted</button>
      </div>
      <ul class="suggestions-list">
        <li v-for="suggestion in sortedSuggestions" :key="suggestion.id" class="suggestion-item">
          <div class="suggestion-box">
            <div class="suggestion-details">
              <p><strong>{{ suggestion.username }}</strong></p>
              <p><strong>Submitted:</strong> {{ new Date(suggestion.timestamp).toLocaleString() }}</p>
              <p v-if="suggestion.editedTimestamp"><strong>Edited:</strong> {{ new Date(suggestion.editedTimestamp).toLocaleString() }}</p>
              <p><strong>Resources:</strong> {{ suggestion.resource || 'No resource' }}</p>
              <div class="suggestion-text">
                <p v-if="editingId !== suggestion.id">{{ suggestion.text }}</p>
                <textarea 
                  v-else 
                  v-model="editingText" 
                  maxlength="5000" 
                  style="width: 100%; height: 150px;">
                </textarea>
              </div>
              <div class="actions">
                <button class="vote-button" 
                        v-if="suggestion.votes !== undefined && !hasVoted(suggestion)" 
                        @click="openVoteModal(suggestion)">
                  Vote ({{ suggestion.votes || 0 }})
                </button>

                <button class="remove-vote-button" 
                        v-if="suggestion.votes !== undefined && hasVoted(suggestion)" 
                        @click="openRemoveVoteModal(suggestion)">
                  Remove Vote ({{ suggestion.votes || 0 }})
                </button>

                <button class="edit-button" 
                        v-if="suggestion.username === currentUser && editingId !== suggestion.id" 
                        @click="startEditing(suggestion)">
                  Edit
                </button>

                <button class="delete-button" 
                        v-if="suggestion.username === currentUser" 
                        @click="deleteSuggestion(suggestion.id)">
                  Delete
                </button>

                <div v-if="editingId === suggestion.id">
                  <button @click="saveEdit(suggestion.id)">Save</button>
                  <button @click="cancelEdit">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- Vote Modal -->
    <div v-if="showVoteModal" class="modal">
      <h3>Allocate Votes</h3>
      <input v-model="votesToAllocate" type="number" min="1" :max="availableVotes">
      <button @click="submitVote">Submit</button>
      <button @click="closeVoteModal">Cancel</button>
    </div>

    <!-- Remove Vote Modal -->
    <div v-if="showRemoveVoteModal" class="modal">
      <h3>Remove Votes</h3>
      <input v-model="votesToRemove" type="number" min="1" :max="currentSuggestion.votes">
      <button @click="submitRemoveVote">Submit</button>
      <button @click="closeRemoveVoteModal">Cancel</button>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/axiosConfig';
import Header from './Header.vue';

export default {
  components: {
    Header,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    topicName: {
      type: String,
      required: true,
    },
    previousTopic: {
      type: String,
      required: true,
    },
    previousUser: {
      type: String,
      required: false,
    },
  },
  data() {
    const storedUser = localStorage.getItem('currentUser');
    const currentUser = storedUser ? JSON.parse(storedUser) : { username: '', votes: 0 };

    return {
      suggestions: [],
      editingId: null,
      editingText: '',
      sortOption: 'mostRecent',
      currentUser: currentUser.username || '',
      availableVotes: currentUser.votes || 0,
      showVoteModal: false,
      showRemoveVoteModal: false,
      votesToAllocate: 1,
      votesToRemove: 1,
      currentSuggestion: null,
    };
  },
  computed: {
    sortedSuggestions() {
      if (this.sortOption === 'mostVoted') {
        return [...this.suggestions].sort((a, b) => b.votes - a.votes);
      }
      return [...this.suggestions].sort((a, b) => b.timestamp - a.timestamp);
    },
    formattedTopicName() {
      return decodeURIComponent(this.topicName).replace(/(.{50})/g, '$1\n');
    },
  },
  async created() {
    await this.loadUserSuggestions();
  },
  methods: {
    async loadUserSuggestions() {
      try {
        const response = await axiosInstance.get(`/suggestions/by-user/${this.username}`);
        this.suggestions = response.data;
      } catch (error) {
        console.error('Failed to load suggestions', error);
      }
    },
    startEditing(suggestion) {
      this.editingId = suggestion.id;
      this.editingText = suggestion.text;
    },
    async saveEdit(id) {
      if (this.editingText.trim() === '') return;

      try {
        await axiosInstance.put(`/suggestions/${id}`, {
          text: this.editingText.trim(),
          editedTimestamp: Date.now(),
        });
        this.editingId = null;
        this.editingText = '';
        await this.loadUserSuggestions();
      } catch (error) {
        console.error('Failed to save edit', error);
      }
    },
    cancelEdit() {
      this.editingId = null;
      this.editingText = '';
    },
    async deleteSuggestion(id) {
      try {
        await axiosInstance.delete(`/suggestions/${id}`);
        await this.loadUserSuggestions();
      } catch (error) {
        console.error('Failed to delete suggestion', error);
      }
    },
    openVoteModal(suggestion) {
      this.currentSuggestion = suggestion;
      this.votesToAllocate = 1;
      this.showVoteModal = true;
    },
    closeVoteModal() {
      this.showVoteModal = false;
      this.currentSuggestion = null;
    },
    async submitVote() {
      const votesToAllocate = this.votesToAllocate;

      if (votesToAllocate > this.availableVotes) {
        alert('You don\'t have enough votes.');
        return;
      }

      try {
        await axiosInstance.post(`/suggestions/${this.currentSuggestion.id}/vote`, { votes: votesToAllocate });
        this.availableVotes -= votesToAllocate;
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        currentUser.votes = this.availableVotes;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        await this.loadUserSuggestions();
      } catch (error) {
        console.error('Failed to vote', error);
      } finally {
        this.closeVoteModal();
      }
    },
    openRemoveVoteModal(suggestion) {
      this.currentSuggestion = suggestion;
      this.votesToRemove = 1;
      this.showRemoveVoteModal = true;
    },
    closeRemoveVoteModal() {
      this.showRemoveVoteModal = false;
      this.currentSuggestion = null;
    },
    async submitRemoveVote() {
      const votesToRemove = this.votesToRemove;

      if (!this.currentSuggestion || votesToRemove > this.currentSuggestion.votes || votesToRemove < 1) {
        alert('Invalid number of votes to remove.');
        return;
      }

      try {
        await axiosInstance.post(`/suggestions/${this.currentSuggestion.id}/remove-vote`, { votes: votesToRemove });
        this.availableVotes += votesToRemove;
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        currentUser.votes = this.availableVotes;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        await this.loadUserSuggestions();
      } catch (error) {
        console.error('Failed to remove vote', error);
      } finally {
        this.closeRemoveVoteModal();
      }
    },
    hasVoted(suggestion) {
      return suggestion.voters.includes(this.currentUser);
    },
    sortByMostRecent() {
      this.sortOption = 'mostRecent';
    },
    sortByMostVoted() {
      this.sortOption = 'mostVoted';
    },
    goBackToUser() {
      this.$router.push({
        name: 'user-details',
        params: {
          username: this.username,
          previousTopic: encodeURIComponent(this.previousTopic),
        },
      });
    },
    navigateToVotesPage() {
      this.$router.push({ name: 'buy-votes', params: { topicName: this.topicName } });
    },
  },
};
</script>

<style scoped>
html, body {
  margin: 0;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  margin-top: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

.suggestions-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.suggestion-item {
  margin: 10px 0;
}

.suggestion-box {
  border: 1px solid #ccc;
  padding: 10px;
}

.suggestion-details {
  text-align: left;
}

.suggestion-text {
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.actions {
  margin-top: 5px;
}

.topic-title {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  width: 100%;
  text-align: center;
}

.sorting-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.sorting-buttons button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin: 0 10px;
}

.sorting-buttons button:hover {
  background-color: #555;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 10000;
}

.modal h3 {
  margin-top: 0;
}

.modal input {
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
}

.modal button {
  margin-right: 10px;
  padding: 10px 15px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.modal button:hover {
  background-color: #555;
}
</style>

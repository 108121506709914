// main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // Include the store
import axios from 'axios';

const app = createApp(App);

// Make axios available globally in the app
app.config.globalProperties.$axios = axios;

app.use(router);
app.use(store); // Use Vuex store
app.mount('#app');

LoginPage.vue: <template>
  <div class="login-container">
    <Header :showBackButton="false" :showLogoutButton="false" />
    <div class="login-form">
      <input type="text" v-model="username" placeholder="Username" maxlength="50" />
      <input type="password" v-model="password" placeholder="Password" />
      <div class="buttons">
        <button @click="login" class="login-button">Login</button>
        <button @click="register" class="register-button">Register</button>
      </div>
      <p v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import axiosInstance from '@/axiosConfig';

export default {
  components: { Header },
  data() {
    return {
      username: '',
      password: '',
      error: '',
    };
  },
  methods: {
    async login() {
      this.error = '';
      try {
        const response = await axiosInstance.post('/login', {
          username: this.username,
          password: this.password,
        });

        if (response.data.success) {
          localStorage.setItem('currentUser', JSON.stringify(response.data.user));
          localStorage.setItem('jwtToken', response.data.token);  // Store the token
          this.$router.push('/home');  // Redirect to home after successful login
        } else {
          this.error = response.data.message || 'Invalid username or password!';
        }
      } catch (error) {
        console.error('Failed to login:', error);
        this.error = error.response?.data?.message || 'An error occurred. Please try again later.';
      }
    },
    async register() {
      this.error = '';
      if (!this.username || !this.password) {
        this.error = 'Please fill in all fields!';
        return;
      }
      try {
        const response = await axiosInstance.post('/register', {
          username: this.username,
          password: this.password,
        });

        if (response.data.success) {
          await this.login();  // Trigger the login after registration
        } else {
          this.error = response.data.message || 'Registration failed. Please try again.';
        }
      } catch (error) {
        console.error('Failed to register user:', error);
        this.error = error.response?.data?.message || 'An error occurred. Please try again later.';
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #f4f4f4;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  max-width: 300px;
  width: 100%;
}

input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

button {
  width: 48%;
  padding: 10px;
  border: none;
  border-radius: 4px;
}

.login-button, .register-button {
  background-color: #555;
  color: white;
  cursor: pointer;
}

.login-button:hover, .register-button:hover {
  background-color: #777;
}

p {
  color: red;
}
</style>
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
html, body {
  margin: 0;
  height: 100%;
  overflow: hidden; /* Prevent body scrolling */
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto; /* Enable scrolling on the app div */
}
</style>

<template>
  <div class="container">
    <Header :showBackButton="true" backText="Back to Articles" @back="goBackToArticles" />
    <div class="content" ref="content">
      <h1>{{ article.title }}</h1>
      <pre>{{ articleContent }}</pre>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import store from '../store';

export default {
  components: {
    Header,
  },
  data() {
    return {
      article: {},
      articleContent: '',
    };
  },
  mounted() {
    const articleId = this.$route.params.articleId;
    this.article = this.getArticleDetails(articleId);
    this.fetchArticleContent(this.article.text).then(() => {
      // After content is loaded, scroll to the saved position
      this.$nextTick(() => {
        const savedPosition = store.getters.getScrollPosition(articleId);
        if (savedPosition) {
          this.$refs.content.scrollTop = savedPosition;
        }
      });
    });
  },
  methods: {
    getArticleDetails(articleId) {
      const articles = [
      { id: 3, title: "Antimicrobial Resistance", text: "/articles/antimicrobial-resistance.txt" },
        { id: 4, title: "Artificial Intelligence Ethics", text: "/articles/artificial-intelligence-ethics.txt" },
        { id: 5, title: "Artificial Intelligence in Healthcare", text: "/articles/artificial-intelligence-in-healthcare.txt" },
        { id: 6, title: "Behavioral Economics", text: "/articles/behavioral-economics.txt" },
        { id: 7, title: "Biodegradable Electronics", text: "/articles/biodegradable-electronics.txt" },
        { id: 8, title: "Biodiversity Hotspots", text: "/articles/biodiversity-hotspots.txt" },
        { id: 9, title: "Biodiversity", text: "/articles/biodiversity.txt" },
        { id: 10, title: "Biofabrication", text: "/articles/biofabrication.txt" },
        { id: 11, title: "Biogeography", text: "/articles/biogeography.txt" },
        { id: 12, title: "Bioluminescence", text: "/articles/bioluminescence.txt" },
        { id: 13, title: "Biomimicry", text: "/articles/biomimicry.txt" },
        { id: 14, title: "Blockchain in Healthcare", text: "/articles/blockchain-in-healthcare.txt" },
        { id: 15, title: "Blockchain Technology", text: "/articles/blockchain-technology.txt" },
        { id: 16, title: "Carbon Capture and Storage", text: "/articles/carbon-capture-and-storage.txt" },
        { id: 17, title: "Citizen Science", text: "/articles/citizen-science.txt" },
        { id: 18, title: "Climate Change Adaptation", text: "/articles/climate-change-adaption.txt" },
        { id: 19, title: "Climate Change", text: "/articles/climate-change.txt" },
        { id: 20, title: "Climate Engineering", text: "/articles/climate-engineering.txt" },
        { id: 21, title: "Cognitive Biases in Decision Making", text: "/articles/cognitive-biases-in-decision-making.txt" },
        { id: 22, title: "Cryptocurrency", text: "/articles/cryptocurrency.txt" },
        { id: 23, title: "Cryptography", text: "/articles/cryptography.txt" },
        { id: 24, title: "Cultural Astronomy", text: "/articles/cultural-astronomy.txt" },
        { id: 25, title: "Cultural Heritage Preservation", text: "/articles/cultural-heritage-preservation.txt" },
        { id: 26, title: "Cultural Sustainability", text: "/articles/cultural-sustainability.txt" },
        { id: 27, title: "Cyber-Physical Systems", text: "/articles/cyber-physical-systems.txt" },
        { id: 28, title: "Cybersecurity", text: "/articles/cybersecurity.txt" },
        { id: 29, title: "Dark Matter and Dark Energy", text: "/articles/dark-matter-and-dark-energy.txt" },
        { id: 30, title: "Dark Tourism", text: "/articles/dark-tourism.txt" },
        { id: 31, title: "Digital Detox", text: "/articles/digital-detox.txt" },
        { id: 32, title: "Digital Humanities", text: "/articles/digital-humanities.txt" },
        { id: 33, title: "Digital Minimalism", text: "/articles/digital-minimalism.txt" },
        { id: 34, title: "Digital Nomadism", text: "/articles/digital-nomadism.txt" },
        { id: 35, title: "Digital Twins in Healthcare", text: "/articles/digital-twins-in-healthcare.txt" },
        { id: 36, title: "Edge Computing", text: "/articles/edge-computing.txt" },
        { id: 37, title: "Emotional Intelligence", text: "/articles/emotional-intelligence.txt" },
        { id: 38, title: "Epigenetics", text: "/articles/epigenetics.txt" },
        { id: 39, title: "Ethical AI", text: "/articles/ethical-ai.txt" },
        { id: 40, title: "Ethical Consumerism", text: "/articles/ethical-consumerism.txt" },
        { id: 41, title: "Forensic Anthropology", text: "/articles/forensic-anthropology.txt" },
        { id: 42, title: "Gamification in Education", text: "/articles/gamification-in-education.txt" },
        { id: 43, title: "Genetic Counseling", text: "/articles/genetic-counseling.txt" },
        { id: 44, title: "Genetic Engineering in Agriculture", text: "/articles/genetic-engineering-in-agriculture.txt" },
        { id: 45, title: "Genetic Engineering", text: "/articles/genetic-engineering.txt" },
        { id: 46, title: "Genetic Genealogy", text: "/articles/genetic-genealogy.txt" },
        { id: 47, title: "Genetics", text: "/articles/genetics.txt" },
        { id: 48, title: "Human Cloning", text: "/articles/human-cloning.txt" },
        { id: 49, title: "Human-Machine Interface", text: "/articles/human-machine-interface.txt" },
        { id: 50, title: "Impressionism", text: "/articles/impressionism.txt" },
        { id: 51, title: "Leonardo Da Vinci", text: "/articles/leonardo-da-vinci.txt" },
        { id: 52, title: "Machine Learning", text: "/articles/machine-learning.txt" },
        { id: 53, title: "Marie Curie", text: "/articles/marie-curie.txt" },
        { id: 54, title: "Marine Biology", text: "/articles/marine-biology.txt" },
        { id: 55, title: "Microbiology", text: "/articles/microbiology.txt" },
        { id: 56, title: "Microbiomes", text: "/articles/microbiomes.txt" },
        { id: 57, title: "Microgrids", text: "/articles/microgrids.txt" },
        { id: 58, title: "Nanotechnology", text: "/articles/nanotechnology.txt" },
        { id: 59, title: "Neuroaesthetics", text: "/articles/neuroaesthetics.txt" },
        { id: 60, title: "Neuroarchitecture", text: "/articles/neuroarchitecture.txt" },
        { id: 61, title: "Neuroplasticity", text: "/articles/neuroplasticity.txt" },
        { id: 62, title: "Neuroscience", text: "/articles/neuroscience.txt" },
        { id: 63, title: "Ocean Acidification", text: "/articles/ocean-acidification.txt" },
        { id: 64, title: "Plant-Based Diets", text: "/articles/plant-based-diets.txt" },
        { id: 65, title: "Quantum Biology", text: "/articles/quantum-biology.txt" },
        { id: 66, title: "Quantum Computing", text: "/articles/quantum-computing.txt" },
        { id: 67, title: "Quantum Cryptography", text: "/articles/quantum-cryptography.txt" },
        { id: 68, title: "Quantum Mechanics", text: "/articles/quantum-mechanics.txt" },
        { id: 69, title: "Quantum Sensors", text: "/articles/quantum-sensors.txt" },
        { id: 70, title: "Regenerative Agriculture", text: "/articles/regenerative-agriculture.txt" },
        { id: 71, title: "Renewable Agriculture", text: "/articles/renewable-agriculture.txt" },
        { id: 72, title: "Renewable Energy Policy", text: "/articles/renewable-energy-policy.txt" },
        { id: 73, title: "Renewable Energy Storage", text: "/articles/renewable-energy-storage.txt" },
        { id: 74, title: "Renewable Energy", text: "/articles/renewable-energy.txt" },
        { id: 75, title: "Renewable Resources", text: "/articles/renewable-resources.txt" },
        { id: 76, title: "Robotics", text: "/articles/robotics.txt" },
        { id: 77, title: "Sircular Economy", text: "/articles/sircular-economy.txt" },
        { id: 78, title: "Smart Cities", text: "/articles/smart-cities.txt" },
        { id: 79, title: "Smart Grids", text: "/articles/smart-grids.txt" },
        { id: 80, title: "Social Media Algorithms", text: "/articles/social-media-algorithms.txt" },
        { id: 81, title: "Space Colonization", text: "/articles/space-colonization.txt" },
        { id: 82, title: "Space Debris Mitigation", text: "/articles/space-debris-mitigation.txt" },
        { id: 83, title: "Space Elevators", text: "/articles/space-elevators.txt" },
        { id: 84, title: "Space Tourism", text: "/articles/space-tourism.txt" },
        { id: 85, title: "Sustainable Agriculture", text: "/articles/sustainable-agriculture.txt" },
        { id: 86, title: "Sustainable Tourism", text: "/articles/sustainable-tourism.txt" },
        { id: 87, title: "Synthetic Biology", text: "/articles/synthetic-biology.txt" },
        { id: 88, title: "The Amazon Rainforest", text: "/articles/the-amazon-rainforest.txt" },
        { id: 89, title: "The Art of Baroque Period", text: "/articles/the-art-of-baroque-period.txt" },
        { id: 90, title: "The Cold War", text: "/articles/the-cold-war.txt" },
        { id: 91, title: "The Crusades", text: "/articles/the-crusades.txt" },
        { id: 92, title: "The Development of Renewable Energy in Technologies", text: "/articles/the-development-of-renewable-energy-in-technologies.txt" },
        { id: 93, title: "The Evolution of Democracy", text: "/articles/the-evolution-of-democracy.txt" },
        { id: 94, title: "The Evolution of the English Language", text: "/articles/the-evolution-of-the-english-language.txt" },
        { id: 95, title: "The Evolution of the Internet", text: "/articles/the-evolution-of-the-internet.txt" },
        { id: 96, title: "The Evolution of Video Game Design", text: "/articles/the-evolution-of-video-game-design.txt" },
        { id: 97, title: "The French Revolution", text: "/articles/the-french-revolution.txt" },
        { id: 98, title: "The Global Water Crisis", text: "/articles/the-global-water-crisis.txt" },
        { id: 99, title: "The Great Barrier Reef", text: "/articles/the-great-barrier-reef.txt" },
        { id: 100, title: "The Great Depression", text: "/articles/the-great-depression.txt" },
        { id: 101, title: "The Green Revolution", text: "/articles/the-green-revolution.txt" },
        { id: 102, title: "The History and Development of Jazz Music", text: "/articles/the-history-and-development-of-jazz-music.txt" },
        { id: 103, title: "The History and Evolution of Architecture", text: "/articles/the-history-and-evolution-of-architecture.txt" },
        { id: 104, title: "The History and Evolution of Computing", text: "/articles/the-history-and-evolution-of-computing.txt" },
        { id: 105, title: "The History and Significance of the Silk Road", text: "/articles/the-history-and-significance-of-the-silk-road.txt" },
        { id: 106, title: "The History of Cryptocurrencies", text: "/articles/the-history-of-cryptocurrencies.txt" },
        { id: 107, title: "The History of Cryptography", text: "/articles/the-history-of-cryptography.txt" },
        { id: 108, title: "The History of Human Rights", text: "/articles/the-history-of-human-rights.txt" },
        { id: 109, title: "The History of Public Health", text: "/articles/the-history-of-public-health.txt" },
        { id: 110, title: "The History of Quantum Physics", text: "/articles/the-history-of-quantum-physics.txt" },
        { id: 111, title: "The History of Space Exploration", text: "/articles/the-history-of-space-exploration.txt" },
        { id: 112, title: "The History of Space Stations", text: "/articles/the-history-of-space-stations.txt" },
        { id: 113, title: "The History of Space Telescopes", text: "/articles/the-history-of-space-telescopes.txt" },
        { id: 114, title: "The History of Space Colonization Concepts", text: "/articles/the-history-of-space-colonization-concepts.txt" },
        { id: 115, title: "The History of the Internet", text: "/articles/the-history-of-the-internet.txt" },
        { id: 116, title: "The History of the Olympics Games", text: "/articles/the-history-of-the-olympics-games.txt" },
        { id: 117, title: "The History of the Printing Press", text: "/articles/the-history-of-the-printing-press.txt" },
        { id: 118, title: "The History of Vaccination", text: "/articles/the-history-of-vaccination.txt" },
        { id: 119, title: "The Human Brain", text: "/articles/the-human-brain.txt" },
        { id: 120, title: "The Human Genome Project", text: "/articles/the-human-genome-project.txt" },
        { id: 121, title: "The Human Immune System", text: "/articles/the-human-immune-system.txt" },
        { id: 122, title: "The Impact of Artificial Intelligence on Art", text: "/articles/the-impact-of-artificial-intelligence-on-art.txt" },
        { id: 123, title: "The Impact of Artificial Intelligence on Education", text: "/articles/the-impact-of-artificial-intelligence-on-education.txt" },
        { id: 124, title: "The Impact of Artificial Intelligence on Employment", text: "/articles/the-impact-of-artificial-intelligence-on-employment.txt" },
        { id: 125, title: "The Impact of Social Media on Politics", text: "/articles/the-impact-of-social-media-on-politics.txt" },
        { id: 126, title: "The Impact of Social Media on Society", text: "/articles/the-impact-of-social-media-on-society.txt" },
        { id: 127, title: "The Industrial Revolution", text: "/articles/the-industrial-revolution.txt" },
        { id: 128, title: "The Internet of Things", text: "/articles/the-internet-of-things.txt" },
        { id: 129, title: "The Ottoman Empire", text: "/articles/the-ottoman-empire.txt" },
        { id: 130, title: "The Philosophy of Science", text: "/articles/the-philosophy-of-science.txt" },
        { id: 131, title: "The Principles of Democracy", text: "/articles/the-principles-of-democracy.txt" },
        { id: 132, title: "The Psychology of Color", text: "/articles/the-psychology-of-color.txt" },
        { id: 133, title: "The Psychology of Procrastination", text: "/articles/the-psychology-of-procrastination.txt" },
        { id: 134, title: "The Renaissance in Art", text: "/articles/the-renaissance-in-art.txt" },
        { id: 135, title: "The Renaissance in Science", text: "/articles/the-renaissance-in-science.txt" },
        { id: 136, title: "The Renaissance", text: "/articles/the-renaissance.txt" },
        { id: 137, title: "The Renewable Energy Transition", text: "/articles/the-renewable-energy-transition.txt" },
        { id: 138, title: "The Rise of E-Commerce", text: "/articles/the-rise-of-e-commerce.txt" },
        { id: 139, title: "The Role of Bees in Agriculture", text: "/articles/the-role-of-bees-in-agriculture.txt" },
        { id: 140, title: "The Role of Education in Society", text: "/articles/the-role-of-education-in-society.txt" },
        { id: 141, title: "The Role of Forensic Science in Criminal Justice", text: "/articles/the-role-of-forensic-science-in-criminal-justice.txt" },
        { id: 142, title: "The Role of Microorganisms in Human Health", text: "/articles/the-role-of-microorganisms-in-human-health.txt" },
        { id: 143, title: "The Role of Music in Human Culture", text: "/articles/the-role-of-music-in-human-culture.txt" },
        { id: 144, title: "The Role of Social Entrepreneurship", text: "/articles/the-role-of-social-entrepreneurship.txt" },
        { id: 145, title: "The Role of Sports in Society", text: "/articles/the-role-of-sports-in-society.txt" },
        { id: 146, title: "The Role of Women in Ancient Civilizations", text: "/articles/the-role-of-women-in-ancient-civilizations.txt" },
        { id: 147, title: "The Role of Women in Science", text: "/articles/the-role-of-women-in-science.txt" },
        { id: 148, title: "The Science of Climate Change", text: "/articles/the-science-of-climate-change.txt" },
        { id: 149, title: "The Science of Happiness", text: "/articles/the-science-of-happiness.txt" },
        { id: 150, title: "The Silk Road", text: "/articles/the-silk-road.txt" },
        { id: 151, title: "The Sociology of Fashion", text: "/articles/the-sociology-of-fashion.txt" },
        { id: 152, title: "The Space Economy", text: "/articles/the-space-economy.txt" },
        { id: 153, title: "The Space Race", text: "/articles/the-space-race.txt" },
        { id: 154, title: "The Space Shuttle Program", text: "/articles/the-space-shuttle-program.txt" },
        { id: 155, title: "The Theory of Evolution", text: "/articles/the-theory-of-evolution.txt" },
        { id: 156, title: "The Theory of Relativity", text: "/articles/the-theory-of-relativity.txt" },
        { id: 157, title: "The United Nations", text: "/articles/the-united-nations.txt" },
        { id: 158, title: "Urban Farming", text: "/articles/urban-farming.txt" },
        { id: 159, title: "Virtual Reality", text: "/articles/virtual-reality.txt" },
        { id: 160, title: "Wearable Technology", text: "/articles/wearable-technology.txt" },
      ];
      return articles.find(article => article.id === parseInt(articleId));
    },
    fetchArticleContent(url) {
      return fetch(url)
        .then(response => response.text())
        .then(content => {
          this.articleContent = content;
        });
    },
    goBackToArticles() {
      this.saveScrollPositionBeforeRouteLeave(); // Save scroll position before navigating away
      this.$router.push('/articles');
    },
    saveScrollPositionBeforeRouteLeave() {
      const articleId = this.$route.params.articleId;
      const scrollPosition = this.$refs.content.scrollTop;
      store.dispatch('saveScrollPosition', { pageId: articleId, position: scrollPosition }); // Save scroll position
    },
  },
  beforeRouteLeave(to, from, next) {
    this.saveScrollPositionBeforeRouteLeave(); // Save scroll position before leaving the route
    next();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex: 1;
  padding: 20px;
  margin-top: 60px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  font-size: 1.2rem;
  overflow-y: auto;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1.2rem;
}
</style>

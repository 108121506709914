<template>
  <div class="container">
    <Header
      :showBackButton="true"
      backText="Back to Get More Resources"
      :showLogoutButton="true"
      @back="goBackToResources"
    />
    <div class="content" ref="content">
      <h1>Search For Books</h1>
      <!-- Search Input -->
      <input type="text" v-model="searchQuery" placeholder="Search for a book" />

      <!-- Genre Buttons -->
      <div class="genres-container">
        <button
          v-for="genre in genres"
          :key="genre"
          @click="filterByGenre(genre)"
          class="genre-button"
        >
          {{ genre }}
        </button>
      </div>

      <!-- Books Grid -->
      <div class="books-grid">
        <div
          v-for="book in filteredBooks"
          :key="book.id"
          class="book-item"
          @click="goToBookDetails(book.id)"
        >
          <img :src="book.image" alt="Book image" class="book-image" />
          <p>{{ book.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Header,
  },
  data() {
    return {
      books: [
      { 'id': 1, 'title': '813 by Maurice Leblanc', 'image': '/813.png', 'genre': 'Classics' },
  { 'id': 2, 'title': 'A Christmas Carol by Charles Dickens', 'image': '/a-christmas-carol.png', 'genre': 'Classics' },
  { 'id': 3, 'title': 'A Tale of Two Cities by Charles Dickens', 'image': '/a-tale-of-two-cities.png', 'genre': 'Classics' },
  { 'id': 4, 'title': 'A Connecticut Yankee in King Arthur\'s Court by Mark Twain', 'image': '/a-connecticut-yankee-in-king-arthurs-court.png', 'genre': 'Classics' },
  { 'id': 5, 'title': 'A Midsummer Night\'s Dream by William Shakespeare', 'image': '/a-midsummer-nights-dream.png', 'genre': 'Classics' },
  { 'id': 6, 'title': 'Alice\'s Adventures in Wonderland by Lewis Carroll', 'image': '/alice-in-wonderland.png', 'genre': 'Classics' },
  { 'id': 7, 'title': 'Anna Karenina by Leo Tolstoy', 'image': '/anna-karenina.png', 'genre': 'Classics' },
  { 'id': 8, 'title': 'Adventures of Huckleberry Finn by Mark Twain', 'image': '/adventures-of-huckleberry.png', 'genre': 'Classics' },
  { 'id': 9, 'title': 'Bleak House by Charles Dickens', 'image': '/bleak-house.png', 'genre': 'Classics' },
  { 'id': 10, 'title': 'A Doll\'s House by Henrik Ibsen', 'image': '/a-doll-s-house.png', 'genre': 'Classics' },
  { 'id': 11, 'title': 'A Room with a View by E. M. Forster', 'image': '/a-room-with-a-view.png', 'genre': 'Classics' },
  { 'id': 12, 'title': 'A Woman of No Importance by Oscar Wilde', 'image': '/a-woman-of-no-importance.png', 'genre': 'Classics' },
  { 'id': 13, 'title': 'Anne of Green Gables by L. M. Montgomery', 'image': '/anne-of-green-gables.png', 'genre': 'Classics' },
  { 'id': 14, 'title': 'Anne of the Island by L. M. Montgomery', 'image': '/anne-of-the-island.png', 'genre': 'Classics' },
  { 'id': 15, 'title': 'A Hero of Our Time by Mikhail Lermontov', 'image': '/a-hero-of-our-time.png', 'genre': 'Classics' },
  { 'id': 16, 'title': 'Adam Bede by George Eliot', 'image': '/adam-bede.png', 'genre': 'Classics' },
  { 'id': 17, 'title': 'A Tramp Abroad by Mark Twain', 'image': '/a-tramp-abroad.png', 'genre': 'Classics' },
  { 'id': 18, 'title': 'Buddenbrooks Verfall Einer Familie by Thomas Mann', 'image': '/buddenbrooks-verfall-einer-familie.png', 'genre': 'Classics' },
  { 'id': 19, 'title': 'Barchester Towers by Anthony Trollope', 'image': '/barchester-towers.png', 'genre': 'Classics' },
  { 'id': 20, 'title': 'Beowulf by Anonymous', 'image': '/beowulf-an-anglo-saxon-epic-poem.png', 'genre': 'Classics' },
  { 'id': 21, 'title': 'Clarissa Harlowe; or, The History of a Young Lady, Volume 1 by Samuel Richardson', 'image': '/clarissa-harlowe-or-the-history-of-a-young-lady-volume-1.png', 'genre': 'Classics' },
  { 'id': 22, 'title': 'Cranford by Elizabeth Gaskell', 'image': '/cranford.png', 'genre': 'Classics' },
  { 'id': 23, 'title': 'Crime and Punishment by Fyodor Dostoevsky', 'image': '/crime-and-punishment.png', 'genre': 'Classics' },
  { 'id': 24, 'title': 'David Copperfield by Charles Dickens', 'image': '/david-copperfield.png', 'genre': 'Classics' },
  { 'id': 25, 'title': 'De Decamerone van Boccaccio by Giovanni Boccaccio', 'image': '/de-decamerone-van-boccaccio.png', 'genre': 'Classics' },
  { 'id': 26, 'title': 'Dead Souls by Nikolai Gogol', 'image': '/dead-souls.png', 'genre': 'Classics' },
  { 'id': 27, 'title': 'Demian by Hermann Hesse', 'image': '/demian.png', 'genre': 'Classics' },
  { 'id': 28, 'title': 'Don Quixote by Miguel de Cervantes Saavedra', 'image': '/don-quixote.png', 'genre': 'Classics' },
  { 'id': 29, 'title': 'Emma by Jane Austen', 'image': '/emma.png', 'genre': 'Classics' },
  { 'id': 30, 'title': 'Eugene Oneguine [Onegin] by Alexander Pushkin', 'image': '/eugene-oneguine-[onegin].png', 'genre': 'Classics' },
  { 'id': 31, 'title': 'Evelina, Or the History of a Young Lady\'s Entrance into the World by Fanny Burney', 'image': '/evelina-or-the-history-of-a-young-ladys-entrance-into-the-world.png', 'genre': 'Classics' },
  { 'id': 32, 'title': 'Faust [Part 1] by Johann Wolfgang von Goethe', 'image': '/faust-[part-1].png', 'genre': 'Classics' },
  { 'id': 33, 'title': 'Faust Der Tragödie Erster Teil by Johann Wolfgang von Goethe', 'image': '/faust-der-tragödie-erster-teil.png', 'genre': 'Classics' },
  { 'id': 34, 'title': 'Faust Der Tragödie Zweiter Teil by Johann Wolfgang von Goethe', 'image': '/faust-der-tragödie-zweiter-teil.png', 'genre': 'Classics' },
  { 'id': 35, 'title': 'Far From the Madding Crowd by Thomas Hardy', 'image': '/far-from-the-madding-crowd.png', 'genre': 'Classics' },
  { 'id': 36, 'title': 'Father Goriot by Honoré de Balzac', 'image': '/father-goriot.png', 'genre': 'Classics' },
  { 'id': 37, 'title': 'Great Expectations by Charles Dickens', 'image': '/great-expectations.png', 'genre': 'Classics' },
  { 'id': 38, 'title': 'Gulliver\'s Travels by Jonathan Swift', 'image': '/gullivers-travels.png', 'genre': 'Classics' },
  { 'id': 39, 'title': 'Heart of Darkness by Joseph Conrad', 'image': '/heart-of-darkness.png', 'genre': 'Classics' },
  { 'id': 40, 'title': 'Hunger by Knut Hamsun', 'image': '/hunger.png', 'genre': 'Classics' },
  { 'id': 41, 'title': 'Jude the Obscure by Thomas Hardy', 'image': '/jude-the-obscure.png', 'genre': 'Classics' },
  { 'id': 43, 'title': 'King Lear by William Shakespeare', 'image': '/king-lear.png', 'genre': 'Classics' },
  { 'id': 44, 'title': 'Lady Audley\'s Secret by Mary Elizabeth Braddon', 'image': '/lady-audleys-secret.png', 'genre': 'Classics' },
  { 'id': 45, 'title': 'Le Comte de Monte Cristo Tome I by Alexandre Dumas', 'image': '/le-comte-de-monte-cristo-tome-I.png', 'genre': 'Classics' },
  { 'id': 46, 'title': 'Les Misérables by Victor Hugo', 'image': '/les-miserables.png', 'genre': 'Classics' },
  { 'id': 47, 'title': 'Lord Jim by Joseph Conrad', 'image': '/lord-jim.png', 'genre': 'Classics' },
  { 'id': 48, 'title': 'Mansfield Park by Jane Austen', 'image': '/mansfield-park.png', 'genre': 'Classics' },
  { 'id': 49, 'title': 'Madame Bovary by Gustave Flaubert', 'image': '/madame-bovary.png', 'genre': 'Classics' },
  { 'id': 50, 'title': 'Moby Dick by Herman Melville', 'image': '/moby-dick.png', 'genre': 'Classics' },
  { 'id': 51, 'title': 'Middlemarch by George Eliot', 'image': '/middlemarch.png', 'genre': 'Classics' },
  { 'id': 52, 'title': 'Northanger Abbey by Jane Austen', 'image': '/northanger-abbey.png', 'genre': 'Classics' },
  { 'id': 53, 'title': 'Notes from the Underground by Fyodor Dostoevsky', 'image': '/notes-from-the-underground.png', 'genre': 'Classics' },
  { 'id': 54, 'title': 'Notre-Dame de Paris Tome I by Victor Hugo', 'image': '/notre-dame-de-paris-tome-I.png', 'genre': 'Classics' },
  { 'id': 55, 'title': 'Oedipus King of Thebes by Sophocles', 'image': '/oedipus-king-of-thebes.png', 'genre': 'Classics' },
  { 'id': 56, 'title': 'Of Human Bondage by W. Somerset Maugham', 'image': '/of-human-bondage.png', 'genre': 'Classics' },
  { 'id': 57, 'title': 'Oliver Twist by Charles Dickens', 'image': '/oliver-twist.png', 'genre': 'Classics' },
  { 'id': 58, 'title': 'Othello, the Moor of Venice by William Shakespeare', 'image': '/othello-the-moor-of-venice.png', 'genre': 'Classics' },
  { 'id': 59, 'title': 'Paradise Lost by John Milton', 'image': '/paradise-lost.png', 'genre': 'Classics' },
  { 'id': 60, 'title': 'Persuasion by Jane Austen', 'image': '/persuasion.png', 'genre': 'Classics' },
  { 'id': 61, 'title': 'Peter Pan by J. M. Barrie', 'image': '/peter-pan.png', 'genre': 'Classics' },
  { 'id': 62, 'title': 'Pride and Prejudice by Jane Austen', 'image': '/pride-and-prejudice.png', 'genre': 'Classics' },
  { 'id': 63, 'title': 'Romeo and Juliet by William Shakespeare', 'image': '/romeo-juliet.png', 'genre': 'Classics' },
  { 'id': 64, 'title': 'She by H. Rider Haggard', 'image': '/she.png', 'genre': 'Classics' },
  { 'id': 65, 'title': 'Siddhartha by Hermann Hesse', 'image': '/siddhartha.png', 'genre': 'Classics' },
  { 'id': 66, 'title': 'Sense and Sensibility by Jane Austen', 'image': '/sense-and-sensibility.png', 'genre': 'Classics' },
  { 'id': 67, 'title': 'Tess of the d\'Urbervilles by Thomas Hardy', 'image': '/tess-of-the-d-urbervilles.png', 'genre': 'Classics' },
  { 'id': 68, 'title': 'The Adventures of Tom Sawyer by Mark Twain', 'image': '/the-adventures-of-tom-sawyer.png', 'genre': 'Classics' },
  { 'id': 69, 'title': 'The Adventures of Sherlock Holmes by Arthur Conan Doyle', 'image': '/the-adventures-of-sherlock.png', 'genre': 'Classics' },
  { 'id': 70, 'title': 'The Aeneid by Virgil', 'image': '/the-aeneid.png', 'genre': 'Classics' },
  { 'id': 71, 'title': 'The Arabian Nights: Their Best-Known Tales by Anonymous', 'image': '/the-arabian-nights-their-best-known-tales.png', 'genre': 'Classics' },
  { 'id': 72, 'title': 'The Brothers Karamazov by Fyodor Dostoevsky', 'image': '/the-brothers-karamazov.png', 'genre': 'Classics' },
  { 'id': 73, 'title': 'The Call of the Wild by Jack London', 'image': '/the-call-of-the-wild.png', 'genre': 'Classics' },
  { 'id': 74, 'title': 'The Canterbury Tales and Other Poems by Geoffrey Chaucer', 'image': '/the-canterbury-tales-and-other-poems.png', 'genre': 'Classics' },
  { 'id': 75, 'title': 'The Count of Monte Cristo by Alexandre Dumas', 'image': '/the-count-of-monte-cristo.png', 'genre': 'Classics' },
  { 'id': 76, 'title': 'The Divine Comedy by Dante Alighieri', 'image': '/the-divine-comedy.png', 'genre': 'Classics' },
  { 'id': 77, 'title': 'The Great Gatsby by F. Scott Fitzgerald', 'image': '/the-great-gatsby.png', 'genre': 'Classics' },
  { 'id': 78, 'title': 'The Iliad by Homer', 'image': '/the-iliad.png', 'genre': 'Classics' },
  { 'id': 79, 'title': 'The Odyssey by Homer', 'image': '/the-odyssey.png', 'genre': 'Classics' },
  { 'id': 80, 'title': 'The Hound of the Baskervilles by Arthur Conan Doyle', 'image': '/the-hound-of-the-baskervilles.png', 'genre': 'Classics' },
  { 'id': 81, 'title': 'The Idiot by Fyodor Dostoevsky', 'image': '/the-idiot.png', 'genre': 'Classics' },
  { 'id': 82, 'title': 'The Importance of Being Earnest by Oscar Wilde', 'image': '/the-importance-of-being-earnest.png', 'genre': 'Classics' },
  { 'id': 83, 'title': 'The Jungle Book by Rudyard Kipling', 'image': '/the-jungle-book.png', 'genre': 'Classics' },
  { 'id': 84, 'title': 'The Legend of Sleepy Hollow by Washington Irving', 'image': '/the-legend-of-sleepy-hollow.png', 'genre': 'Classics' },
  { 'id': 85, 'title': 'The Prince and the Pauper by Mark Twain', 'image': '/the-prince-and-the-pauper.png', 'genre': 'Classics' },
  { 'id': 86, 'title': 'The House of the Seven Gables by Nathaniel Hawthorne', 'image': '/the-house-of-the-seven-gables.png', 'genre': 'Classics' },
  { 'id': 87, 'title': 'The Metamorphoses of Ovid by Ovid', 'image': '/the-metamorphoses-of-ovid.png', 'genre': 'Classics' },
  { 'id': 88, 'title': 'The Scarlet Letter by Nathaniel Hawthorne', 'image': '/the-scarlet-letter.png', 'genre': 'Classics' },
  { 'id': 89, 'title': 'The Three Musketeers by Alexandre Dumas', 'image': '/the-three-musketeers.png', 'genre': 'Classics' },
  { 'id': 90, 'title': 'The Time Machine by H. G. Wells', 'image': '/the-time-machine.png', 'genre': 'Classics' },
  { 'id': 91, 'title': 'The Scarlet Pimpernel by Baroness Orczy', 'image': '/the-scarlet-pimpernel.png', 'genre': 'Classics' },
  { 'id': 92, 'title': 'The Scarlet Plague by Jack London', 'image': '/the-scarlet-plague.png', 'genre': 'Classics' },
  { 'id': 93, 'title': 'The Strange Case of Dr Jekyll and Mr Hyde by Robert Louis Stevenson', 'image': '/the-strange-case.png', 'genre': 'Classics' },
  { 'id': 94, 'title': 'The Turn of the Screw by Henry James', 'image': '/the-turn-of-the-screw.png', 'genre': 'Classics' },
  { 'id': 95, 'title': 'The Trial by Franz Kafka', 'image': '/the-trial.png', 'genre': 'Classics' },
  { 'id': 96, 'title': 'The War of the Worlds by H. G. Wells', 'image': '/the-war-of-the-worlds.png', 'genre': 'Classics' },
  { 'id': 97, 'title': 'The Wind in the Willows by Kenneth Grahame', 'image': '/the-wind-in-the-willows.png', 'genre': 'Classics' },
  { 'id': 98, 'title': 'The Wonderful Wizard of Oz by L. Frank Baum', 'image': '/the-wonderful-wizard-of-oz.png', 'genre': 'Classics' },
  { 'id': 99, 'title': 'The Republic by Plato', 'image': '/the-republic.png', 'genre': 'Classics' },
  { 'id': 100, 'title': 'Treasure Island by Robert Louis Stevenson', 'image': '/treasure-island.png', 'genre': 'Classics' },
  { 'id': 101, 'title': 'Twelfth Night; Or, What You Will by William Shakespeare', 'image': '/twelfth-night-or-what-you-will.png', 'genre': 'Classics' },
  { 'id': 102, 'title': 'Twenty Thousand Leagues Under the Sea by Jules Verne', 'image': '/twenty-thousand-leagues-under-the-sea.png', 'genre': 'Classics' },
  { 'id': 103, 'title': 'War and Peace by Leo Tolstoy', 'image': '/war-and-peace.png', 'genre': 'Classics' },
  { 'id': 104, 'title': 'Wuthering Heights by Emily Brontë', 'image': '/wuthering-heights.png', 'genre': 'Classics' },
  { 'id': 105, 'title': 'Jane Eyre by Charlotte Brontë', 'image': '/jane-eyre.png', 'genre': 'Classics' },
  { 'id': 106, 'title': 'Ulysses by James Joyce', 'image': '/ulysses.png', 'genre': 'Classics' },
  { 'id': 107, 'title': 'Ivanhoe by Sir Walter Scott', 'image': '/ivanhoe.png', 'genre': 'Classics' },
  { 'id': 108, 'title': 'Twice-Told Tales by Nathaniel Hawthorne', 'image': '/twice-told-tales.png', 'genre': 'Classics' },
 { 'id': 109, 'title': 'A Journey Into the Interior of the Earth by Jules Verne', 'image': '/a-journey-into-the-interior-of-the-earth.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 110, 'title': 'A Princess of Mars by Edgar Rice Burroughs', 'image': '/a-princess-of-mars.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 111, 'title': 'Alice\'s Adventures in Wonderland by Lewis Carroll', 'image': '/alice-in-wonderland.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 112, 'title': 'Beyond Lies the Wub by Philip K. Dick', 'image': '/beyond-lies-the-wub.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 113, 'title': 'Around the World in Eighty Days by Jules Verne', 'image': '/around-the-world-in-eighty-days.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 114, 'title': 'After London or Wild England by Richard Jefferies', 'image': '/after-london-or-wild-england.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 115, 'title': 'A Voyage to Arcturus by David Lindsay', 'image': '/a-voyage-to-arcturus.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 116, 'title': 'A Connecticut Yankee in King Arthur\'s Court by Mark Twain', 'image': '/a-connecticut-yankee-in-king-arthurs-court.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 117, 'title': 'Amphitryo by Plautus', 'image': '/amphitryo.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 118, 'title': 'Childe Harold\'s Pilgrimage by Lord Byron', 'image': '/childe-harolds-pilgrimage.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 119, 'title': 'A Pail of Air by Fritz Leiber', 'image': '/a-pail-of-air.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 120, 'title': 'Etidorhpa, or The End of Earth by John Uri Lloyd', 'image': '/etidorhpa-or-the-end-of-earth.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 121, 'title': 'Five Children and It by E. Nesbit', 'image': '/five-children-and-it.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 122, 'title': 'Herland by Charlotte Perkins Gilman', 'image': '/herland.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 123, 'title': 'Frankenstein by Mary Wollstonecraft Shelley', 'image': '/frankenstein.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 124, 'title': 'L\'Île Mystérieuse by Jules Verne', 'image': '/lile-mysterieuse.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 125, 'title': 'King Solomon\'s Mines by H. Rider Haggard', 'image': '/king-solomons-mines.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 126, 'title': 'Metamorphosis by Franz Kafka', 'image': '/metamorphosis.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 127, 'title': 'Peter Pan by J. M. Barrie', 'image': '/peter-pan.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 128, 'title': 'Swiss Family Robinson by Johann David Wyss', 'image': '/swiss-family-robinson.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 129, 'title': 'She by H. Rider Haggard', 'image': '/she.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 130, 'title': 'The Book of Dragons', 'image': '/the-book-of-dragons.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 131, 'title': 'The Call of the Wild', 'image': '/the-call-of-the-wild.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 132, 'title': 'The Dunwich Horror', 'image': '/the-dunwich-horror.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 133, 'title': 'The Coming Race', 'image': '/the-coming-race.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 134, 'title': 'The Island of Doctor Moreau by H.G. Wells', 'image': '/the-island-of-doctor-moreau.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 135, 'title': 'The Invisible Man: A Grotesque Romance by H.G. Wells', 'image': '/the-invisible-man-a-grotesque-romance.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 136, 'title': 'The King in Yellow by Robert W. Chambers', 'image': '/the-king-in-yellow.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 137, 'title': 'The Princess and the Goblin by George MacDonald', 'image': '/the-princess-and-the-goblin.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 138, 'title': 'The House on the Borderland by William Hope Hodgson', 'image': '/the-house-on-the-borderland.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 139, 'title': 'The Last Man by Mary Wollstonecraft Shelley', 'image': '/the-last-man.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 140, 'title': 'The Time Machine by H. G. Wells', 'image': '/the-time-machine.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 141, 'title': 'The War of the Worlds by H. G. Wells', 'image': '/the-war-of-the-worlds.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 142, 'title': 'The Wonderful Wizard of Oz by L. Frank Baum', 'image': '/the-wonderful-wizard-of-oz.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 143, 'title': 'The Secret Garden by Frances Hodgson Burnett', 'image': '/the-secret-garden.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 144, 'title': 'The Tale of Peter Rabbit by Beatrix Potter', 'image': '/the-tale-of-peter-rabbit.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 145, 'title': 'The Tale of Tom Kitten by Beatrix Potter', 'image': '/the-tale-of-tom-kitten.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 146, 'title': 'The Vampyre; A Tale by John William Polidori', 'image': '/the-vampyre-a-tale.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 147, 'title': 'Twenty Thousand Leagues Under the Sea by Jules Verne', 'image': '/twenty-thousand-leagues-under-the-sea.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 148, 'title': 'We by Yevgeny Zamyatin', 'image': '/we.png', 'genre': 'Science Fiction & Fantasy' },
{ 'id': 149, 'title': '813 by Maurice Leblanc', 'image': '/813.png', 'genre': 'Mystery & Thriller' },
{ 'id': 150, 'title': 'A Study in Scarlet by Arthur Conan Doyle', 'image': '/a-study-in-scarlet.png', 'genre': 'Mystery & Thriller' },
{ 'id': 151, 'title': 'Arsène Lupin, Gentleman Burglar by Maurice Leblanc', 'image': '/arsene-lupin-gentleman-cambrioleur.png', 'genre': 'Mystery & Thriller' },
{ 'id': 152, 'title': 'A Hero of Our Time by Mikhail Lermontov', 'image': '/a-hero-of-our-time.png', 'genre': 'Mystery & Thriller' },
{ 'id': 153, 'title': 'Carmilla by Sheridan Le Fanu', 'image': '/carmilla.png', 'genre': 'Mystery & Thriller' },
{ 'id': 154, 'title': 'Captain Blood by Rafael Sabatini', 'image': '/captain-blood.png', 'genre': 'Mystery & Thriller' },
{ 'id': 155, 'title': 'Brewster\'s Millions by George Barr McCutcheon', 'image': '/brewsters-millions.png', 'genre': 'Mystery & Thriller' },
{ 'id': 156, 'title': 'Dangerous Connections V 1, 2, 3, 4 by Pierre Choderlos de Laclos', 'image': '/dangerous-connections-v-1,-2,-3,-4.png', 'genre': 'Mystery & Thriller' },
{ 'id': 157, 'title': 'Dracula by Bram Stoker', 'image': '/dracula.png', 'genre': 'Mystery & Thriller' },
{ 'id': 158, 'title': 'Ghost Stories of an Antiquary by M. R. James', 'image': '/ghost-stories-of-an-antiquary.png', 'genre': 'Mystery & Thriller' },
{ 'id': 159, 'title': 'Guy Falconer by William Le Queux', 'image': '/guy-falconer.png', 'genre': 'Mystery & Thriller' },
{ 'id': 160, 'title': 'Famous Modern Ghost Stories by Various Authors', 'image': '/famous-modern-ghost-stories.png', 'genre': 'Mystery & Thriller' },
{ 'id': 161, 'title': 'Hidden Symbolism of Alchemy and the Occult Arts by Herbert Silberer', 'image': '/hidden-symbolism-of-alchemy-and-the-occult-arts.png', 'genre': 'Mystery & Thriller' },
{ 'id': 162, 'title': 'Lady Audley\'s Secret by Mary Elizabeth Braddon', 'image': '/lady-audleys-secret.png', 'genre': 'Mystery & Thriller' },
{ 'id': 163, 'title': 'Minute Mysteries [Detectograms] by Various Authors', 'image': '/minute-mysteries-[detectograms].png', 'genre': 'Mystery & Thriller' },
{ 'id': 164, 'title': 'Le Comte de Monte Cristo Tome I by Alexandre Dumas', 'image': '/le-comte-de-monte-cristo-tome-I.png', 'genre': 'Mystery & Thriller' },
{ 'id': 165, 'title': 'Tales of Terror and Mystery by Various Authors', 'image': '/tales-of-terror-and-mystery.png', 'genre': 'Mystery & Thriller' },
{ 'id': 166, 'title': 'The Adventures of Sherlock Holmes', 'image': '/the-adventures-of-sherlock.png', 'genre': 'Mystery & Thriller' },
{ 'id': 167, 'title': 'The Blonde Lady', 'image': '/the-blonde-lady.png', 'genre': 'Mystery & Thriller' },
{ 'id': 168, 'title': 'The Crystal Stopper', 'image': '/the-crystal-stopper.png', 'genre': 'Mystery & Thriller' },
{ 'id': 169, 'title': 'The Cask', 'image': '/the-cask.png', 'genre': 'Mystery & Thriller' },
{ 'id': 170, 'title': 'The Hound of the Baskervilles by Arthur Conan Doyle', 'image': '/the-hound-of-the-baskervilles.png', 'genre': 'Mystery & Thriller' },
{ 'id': 171, 'title': 'The Murder of Roger Ackroyd by Agatha Christie', 'image': '/the-murder-of-roger-ackroyd.png', 'genre': 'Mystery & Thriller' },
{ 'id': 172, 'title': 'The Mysterious Affair at Styles by Agatha Christie', 'image': '/the-mysterious-affair-at-styles.png', 'genre': 'Mystery & Thriller' },
{ 'id': 173, 'title': 'The Memoirs of Sherlock Holmes by Arthur Conan Doyle', 'image': '/the-memoirs-of-sherlock-holmes.png', 'genre': 'Mystery & Thriller' },
{ 'id': 174, 'title': 'The Moonstone by Wilkie Collins', 'image': '/the-moonstone.png', 'genre': 'Mystery & Thriller' },
{ 'id': 175, 'title': 'The Mysterious Stranger and Other Stories by Mark Twain', 'image': '/the-mysterious-stranger-and-other-stories.png', 'genre': 'Mystery & Thriller' },
{ 'id': 176, 'title': 'The Mystery of the Blue Train by Agatha Christie', 'image': '/the-mystery-of-the-blue-train.png', 'genre': 'Mystery & Thriller' },
{ 'id': 177, 'title': 'The Return of Sherlock Holmes by Arthur Conan Doyle', 'image': '/the-return-of-sherlock-holmes.png', 'genre': 'Mystery & Thriller' },
{ 'id': 178, 'title': 'The Sign of the Four by Arthur Conan Doyle', 'image': '/the-sign-of-the-four.png', 'genre': 'Mystery & Thriller' },
{ 'id': 179, 'title': 'The Red House Mystery by A.A. Milne', 'image': '/the-red-house-mystery.png', 'genre': 'Mystery & Thriller' },
{ 'id': 180, 'title': 'The Valley of Fear by Arthur Conan Doyle', 'image': '/the-valley-of-fear.png', 'genre': 'Mystery & Thriller' },
{ 'id': 181, 'title': 'The Secret Tomb by Maurice Leblanc', 'image': '/the-secret-tomb.png', 'genre': 'Mystery & Thriller' },
{ 'id': 182, 'title': 'Uncle Silas: A Tale of Bartram-Haugh by J. Sheridan Le Fanu', 'image': '/uncle-silas-a-tale-of-bartram-haugh.png', 'genre': 'Mystery & Thriller' },
{ 'id': 183, 'title': 'Whose Body? by Dorothy L. Sayers', 'image': '/whose-body.png', 'genre': 'Mystery & Thriller' },
{ 'id': 184, 'title': 'Wieland or the Transformation: An American Tale by Charles Brockden Brown', 'image': '/wieland-or-the-transformation-an-american-tale.png', 'genre': 'Mystery & Thriller' },
{ 'id': 185, 'title': 'Also sprach Zarathustra by Friedrich Nietzsche', 'image': '/also-sprach-zarathustra.png', 'genre': 'Philosophy' },
{ 'id': 187, 'title': 'A Vindication of the Rights of Woman by Mary Wollstonecraft', 'image': '/a-vindication-of-the-rights-of-woman.png', 'genre': 'Philosophy' },
{ 'id': 188, 'title': 'Beyond Good and Evil by Friedrich Nietzsche', 'image': '/beyond-good-and-evil.png', 'genre': 'Philosophy' },
{ 'id': 189, 'title': 'A Discourse Upon the Origin and the Foundation of the Inequality Among Mankind by Jean-Jacques Rousseau', 'image': '/a-discourse-upon-the-origin-and-the-foundation-of-the-inequality-among-mankind.png', 'genre': 'Philosophy' },
{ 'id': 190, 'title': 'An Essay Concerning Human Understanding Volume 1 by John Locke', 'image': '/an-essay-concerning-human-understanding-volume-1.png', 'genre': 'Philosophy' },
{ 'id': 191, 'title': 'Apology, Crito, and Phaedo of Socrates by Plato', 'image': '/apology-crito-and-phaedo-of-socrates.png', 'genre': 'Philosophy' },
{ 'id': 192, 'title': 'Aristotle on the Art of Poetry by Aristotle', 'image': '/aristotle-on-the-art-of-poetry.png', 'genre': 'Philosophy' },
{ 'id': 193, 'title': 'Apology by Plato', 'image': '/apology.png', 'genre': 'Philosophy' },
{ 'id': 194, 'title': 'Areopagitica by John Milton', 'image': '/areopagitica.png', 'genre': 'Philosophy' },
{ 'id': 195, 'title': 'Common Sense by Thomas Paine', 'image': '/common-sense.png', 'genre': 'Philosophy' },
{ 'id': 196, 'title': 'Dao de Jing: A Minimalist Translation by Laozi', 'image': '/dao-de-jing-a-minimalist-translation.png', 'genre': 'Philosophy' },
{ 'id': 197, 'title': 'Dialogues Concerning Natural Religion by David Hume', 'image': '/dialogues-concerning-natural-religion.png', 'genre': 'Philosophy' },
{ 'id': 198, 'title': 'Discourse on the Method of Rightly Conducting One\'s Reason and of Seeking Truth in the Sciences by René Descartes', 'image': '/discourse-on-the-method-of-rightly-conducting-ones-reason-and-of-seeking-truth-in-the-sciences.png', 'genre': 'Philosophy' },
{ 'id': 199, 'title': 'Democracy and Education: An Introduction to the Philosophy of Education by John Dewey', 'image': '/democracy-and-education-an-introduction-to-the-philosophy-of-education.png', 'genre': 'Philosophy' },
{ 'id': 200, 'title': 'Ecce Homo by Friedrich Nietzsche', 'image': '/ecce-homo.png', 'genre': 'Philosophy' },
{ 'id': 201, 'title': 'Emile by Jean-Jacques Rousseau', 'image': '/emile.png', 'genre': 'Philosophy' },
{ 'id': 202, 'title': 'Essays by Ralph Waldo Emerson', 'image': '/essays-by-ralph-waldo-emerson.png', 'genre': 'Philosophy' },
{ 'id': 203, 'title': 'Essays of Michel de Montaigne by Michel de Montaigne', 'image': '/essays-of-michel-de-montaigne.png', 'genre': 'Philosophy' },
{ 'id': 204, 'title': 'Essays of Schopenhauer by Arthur Schopenhauer', 'image': '/essays-of-schopenhauer.png', 'genre': 'Philosophy' },
{ 'id': 205, 'title': 'Ethics by Benedict de Spinoza', 'image': '/ethics.png', 'genre': 'Philosophy' },
{ 'id': 206, 'title': 'Fundamental Principles of the Metaphysic of Morals by Immanuel Kant', 'image': '/fundamental-principles-of-the-metaphysic-of-morals.png', 'genre': 'Philosophy' },
{ 'id': 207, 'title': 'Heretics by G. K. Chesterton', 'image': '/heretics.png', 'genre': 'Philosophy' },
{ 'id': 208, 'title': 'Laws by Plato', 'image': '/laws.png', 'genre': 'Philosophy' },
{ 'id': 209, 'title': 'Leviathan by Thomas Hobbes', 'image': '/leviathan.png', 'genre': 'Philosophy' },
{ 'id': 210, 'title': 'Laughter: An Essay on the Meaning of the Comic by Henri Bergson', 'image': '/laughter-an-essay-on-the-meaning-of-the-comic.png', 'genre': 'Philosophy' },
{ 'id': 211, 'title': 'Meditations by Marcus Aurelius', 'image': '/meditations.png', 'genre': 'Philosophy' },
{ 'id': 212, 'title': 'Man and Superman: A Comedy and a Philosophy by George Bernard Shaw', 'image': '/man-and-superman-a-comedy-and-a-philosophy.png', 'genre': 'Philosophy' },
{ 'id': 213, 'title': 'On Liberty by John Stuart Mill', 'image': '/on-liberty.png', 'genre': 'Philosophy' },
{ 'id': 214, 'title': 'On the Nature of Things by Lucretius', 'image': '/on-the-nature-of-things.png', 'genre': 'Philosophy' },
{ 'id': 215, 'title': 'Perpetual Peace: A Philosophical Essay by Immanuel Kant', 'image': '/perpetual-peace-a-philosophical-essay.png', 'genre': 'Philosophy' },
{ 'id': 216, 'title': 'Politics: A Treatise on Government by Aristotle', 'image': '/politics-a-treatise-on-government.png', 'genre': 'Philosophy' },
{ 'id': 217, 'title': 'Phaedo, Phaedrus, Symposium by Plato', 'image': '/phaedo.png', 'genre': 'Philosophy' },
{ 'id': 218, 'title': 'Pragmatism: A New Name for Some Old Ways of Thinking by William James', 'image': '/pragmatism-a-new-name-for-some-old-ways-of-thinking.png', 'genre': 'Philosophy' },
{ 'id': 219, 'title': 'Principia Ethica by G. E. Moore', 'image': '/principia-ethica.png', 'genre': 'Philosophy' },
{ 'id': 220, 'title': 'Relativity: The Special and General Theory by Albert Einstein', 'image': '/relativity-the-special-and-general-theory.png', 'genre': 'Philosophy' },
{ 'id': 221, 'title': 'The Art of War', 'image': '/the-art-of-war.png', 'genre': 'Philosophy' },
{ 'id': 222, 'title': 'The Birth of Tragedy', 'image': '/the-birth-of-tragedy.png', 'genre': 'Philosophy' },
{ 'id': 223, 'title': 'The Analects of Confucius', 'image': '/the-analects-of-confucius.png', 'genre': 'Philosophy' },
{ 'id': 224, 'title': 'The Consolation of Philosophy', 'image': '/the-consolation-of-philosophy.png', 'genre': 'Philosophy' },
{ 'id': 225, 'title': 'The Critique of Pure Reason', 'image': '/the-critique-of-pure-reason.png', 'genre': 'Philosophy' },
{ 'id': 226, 'title': 'The Dawn of Day', 'image': '/the-dawn-of-day.png', 'genre': 'Philosophy' },
{ 'id': 227, 'title': 'The Ethics of Aristotle', 'image': '/the-ethics-of-aristotle.png', 'genre': 'Philosophy' },
{ 'id': 228, 'title': 'The Genealogy of Morals', 'image': '/the-genealogy-of-morals.png', 'genre': 'Philosophy' },
{ 'id': 229, 'title': 'The Imitation of Christ by Thomas à Kempis', 'image': '/the-imitation-of-christ.png', 'genre': 'Philosophy' },
{ 'id': 230, 'title': 'The Prince by Niccolò Machiavelli', 'image': '/the-prince.png', 'genre': 'Philosophy' },
{ 'id': 231, 'title': 'The Joyful Wisdom (La Gaya Scienza) by Friedrich Nietzsche', 'image': '/the-joyful-wisdom-(la-gaya-scienza).png', 'genre': 'Philosophy' },
{ 'id': 232, 'title': 'The Problems of Philosophy by Bertrand Russell', 'image': '/the-problems-of-philosophy.png', 'genre': 'Philosophy' },
{ 'id': 233, 'title': 'The Meaning of Relativity by Albert Einstein', 'image': '/the-meaning-of-relativity.png', 'genre': 'Philosophy' },
{ 'id': 234, 'title': 'The Polity of the Athenians and the Lacedaemonians by Xenophon', 'image': '/the-polity-of-the-athenians-and-the-lacedaemonians.png', 'genre': 'Philosophy' },
{ 'id': 235, 'title': 'The Kama Sutra', 'image': '/the-kama-sutra.png', 'genre': 'Philosophy' },
{ 'id': 236, 'title': 'The Republic by Plato', 'image': '/the-republic.png', 'genre': 'Philosophy' },
{ 'id': 237, 'title': 'The Tao-Teh-King or The Tao and Its Characteristics by Laozi', 'image': '/the-tao-teh-king-or-the-tao-and-its-characteristics.png', 'genre': 'Philosophy' },
{ 'id': 238, 'title': 'The Twilight of the Idols or How to Philosophize with the Hammer by Friedrich Nietzsche', 'image': '/the-twilight-of-the-idols-or-how-to-philosophize-with-the-hammer.png', 'genre': 'Philosophy' },
{ 'id': 239, 'title': 'The Will to Believe and Other Essays in Popular Philosophy by William James', 'image': '/the-will-to-believe-and-other-essays-in-popular-philosophy.png', 'genre': 'Philosophy' },
{ 'id': 240, 'title': 'The Will to Power: An Attempted Transvaluation of all Values Book I and II by Friedrich Nietzsche', 'image': '/the-will-to-power-an-attempted-transvaluation-of-all-values-book-I-and-II.png', 'genre': 'Philosophy' },
{ 'id': 241, 'title': 'Tractatus Logico-Philosophicus by Ludwig Wittgenstein', 'image': '/tractatus-logico.png', 'genre': 'Philosophy' },
{ 'id': 242, 'title': 'Thus Spake Zarathustra by Friedrich Nietzsche', 'image': '/thus-spake-zarathustra.png', 'genre': 'Philosophy' },
{ 'id': 243, 'title': 'Tragic Sense of Life by Miguel de Unamuno', 'image': '/tragic-sense-of-life.png', 'genre': 'Philosophy' },
{ 'id': 244, 'title': 'Utopia by Thomas More', 'image': '/utopia.png', 'genre': 'Philosophy' },
{ 'id': 245, 'title': 'In Praise of Folly by Desiderius Erasmus', 'image': '/in-praise-of-folly.png', 'genre': 'Philosophy' },
{ 'id': 246, 'title': 'Institutes of the Christian Religion (Vol. 1 of 2) by John Calvin', 'image': '/institutes-of-the-christian-religion-(vol.-1-of-2).png', 'genre': 'Philosophy' },
{ 'id': 247, 'title': 'A Doll\'s House by Henrik Ibsen', 'image': '/a-doll-s-house.png', 'genre': 'Drama & Plays' },
{ 'id': 248, 'title': 'A Midsummer Night\'s Dream by William Shakespeare', 'image': '/a-midsummer-nights-dream.png', 'genre': 'Drama & Plays' },
{ 'id': 249, 'title': 'Hamlet in Greek by William Shakespeare', 'image': '/hamlet-in-greek.png', 'genre': 'Drama & Plays' },
{ 'id': 250, 'title': 'An Enemy of the People by Henrik Ibsen', 'image': '/an-enemy-of-the-people.png', 'genre': 'Drama & Plays' },
{ 'id': 251, 'title': 'A Woman of No Importance by Oscar Wilde', 'image': '/a-woman-of-no-importance.png', 'genre': 'Drama & Plays' },
{ 'id': 252, 'title': 'A Modest Proposal by Jonathan Swift', 'image': '/a-modest-proposal.png', 'genre': 'Drama & Plays' },
{ 'id': 253, 'title': 'A Tale of a Tub by Jonathan Swift', 'image': '/a-tale-of-a-tub.png', 'genre': 'Drama & Plays' },
{ 'id': 254, 'title': 'A Child\'s Dream of a Star by Charles Dickens', 'image': '/a-childs-dream-of-a-star.png', 'genre': 'Drama & Plays' },
{ 'id': 255, 'title': 'Das Leben und der Tod des Königs Lear by William Shakespeare', 'image': '/das-leben-und-der-tod-des-königs-lear.png', 'genre': 'Drama & Plays' },
{ 'id': 256, 'title': 'Every Man in His Humor by Ben Jonson', 'image': '/every-man-in-his-humor.png', 'genre': 'Drama & Plays' },
{ 'id': 257, 'title': 'Everyman with Other Interludes including Eight Miracle Plays by Anonymous', 'image': '/everyman-with-other-interludes-including-eight-miracle-plays.png', 'genre': 'Drama & Plays' },
{ 'id': 258, 'title': 'Hamlet (French) by William Shakespeare', 'image': '/hamlet-(french).png', 'genre': 'Drama & Plays' },
{ 'id': 260, 'title': 'Hamlet Prinz von Dänemark by William Shakespeare', 'image': '/hamlet-prinz-von-dänemark.png', 'genre': 'Drama & Plays' },
{ 'id': 261, 'title': 'Hamlet the Prince of Denmark by William Shakespeare', 'image': '/hamlet-the-prince-of-denmark.png', 'genre': 'Drama & Plays' },
{ 'id': 262, 'title': 'Hamleto Regido da Danujo by William Shakespeare', 'image': '/hamleto-regido-da-danujo.png', 'genre': 'Drama & Plays' },
{ 'id': 263, 'title': 'King Lear by William Shakespeare', 'image': '/king-lear.png', 'genre': 'Drama & Plays' },
{ 'id': 264, 'title': 'Macbeth by William Shakespeare', 'image': '/macbeth.png', 'genre': 'Drama & Plays' },
{ 'id': 265, 'title': 'Lysistrata by Aristophanes', 'image': '/lysistrata.png', 'genre': 'Drama & Plays' },
{ 'id': 266, 'title': 'Life is a Dream by Pedro Calderón de la Barca', 'image': '/life-is-a-dream.png', 'genre': 'Drama & Plays' },
{ 'id': 267, 'title': 'Medea of Euripides by Euripides', 'image': '/medea-of-euripides.png', 'genre': 'Drama & Plays' },
{ 'id': 268, 'title': 'Oedipus King of Thebes by Sophocles', 'image': '/oedipus-king-of-thebes.png', 'genre': 'Drama & Plays' },
{ 'id': 269, 'title': 'Much Ado About Nothing by William Shakespeare', 'image': '/much-ado-about-nothing.png', 'genre': 'Drama & Plays' },
{ 'id': 270, 'title': 'Othello, the Moor of Venice by William Shakespeare', 'image': '/othello-the-moor-of-venice.png', 'genre': 'Drama & Plays' },
{ 'id': 271, 'title': 'Pygmalion by George Bernard Shaw', 'image': '/pygmalion.png', 'genre': 'Drama & Plays' },
{ 'id': 272, 'title': 'Romeo and Juliet by William Shakespeare', 'image': '/romeo-juliet.png', 'genre': 'Drama & Plays' },
{ 'id': 273, 'title': 'Salomé: A Tragedy in One Act by Oscar Wilde', 'image': '/salome-a-tragedy-in-one-act.png', 'genre': 'Drama & Plays' },
{ 'id': 274, 'title': 'Sakoontala or The Lost Ring: An Indian Drama by Kalidasa', 'image': '/sakoontala-or-the-lost-ring-an-indian-drama.png', 'genre': 'Drama & Plays' },
{ 'id': 275, 'title': 'Puphejmo Dramo en tri Aktoj by Nikolaj Nekrasov', 'image': '/puphejmo-dramo-en-tri-aktoj.png', 'genre': 'Drama & Plays' },
{ 'id': 276, 'title': 'She Stoops to Conquer by Oliver Goldsmith', 'image': '/she-stoops-to-conquer.png', 'genre': 'Drama & Plays' },
{ 'id': 277, 'title': 'Plays of Sophocles: Oedipus the King, Oedipus at Colonus, Antigone by Sophocles', 'image': '/plays-of-sophocles-oedipus-the-king-oedipus-at-colonus-antigone.png', 'genre': 'Drama & Plays' },
{ 'id': 278, 'title': 'Plays: The Father, Countess Julie, The Outlaw, The Stronger by August Strindberg', 'image': '/plays-the-father-countess-julie-the-outlaw-the-stronger.png', 'genre': 'Drama & Plays' },
{ 'id': 279, 'title': 'Phaedra by Lucius Annaeus Seneca', 'image': '/phaedra.png', 'genre': 'Drama & Plays' },
{ 'id': 280, 'title': 'Tartuffe or The Hypocrite by Molière', 'image': '/tartuffe-or-the-hypocrite.png', 'genre': 'Drama & Plays' },
{ 'id': 281, 'title': 'The Beggar\'s Opera', 'image': '/the-beggars-opera.png', 'genre': 'Drama & Plays' },
{ 'id': 282, 'title': 'The Fall of the House of Usher', 'image': '/the-fall-of-the-house-of-usher.png', 'genre': 'Drama & Plays' },
{ 'id': 283, 'title': 'The Hairy Ape', 'image': '/the-hairy-ape.png', 'genre': 'Drama & Plays' },
{ 'id': 284, 'title': 'The Imaginary Invalid by Molière', 'image': '/the-imaginary-invalid.png', 'genre': 'Drama & Plays' },
{ 'id': 285, 'title': 'The Importance of Being Earnest by Oscar Wilde', 'image': '/the-importance-of-being-earnest.png', 'genre': 'Drama & Plays' },
{ 'id': 286, 'title': 'The Inspector-General by Nikolai Gogol', 'image': '/the-inspector-general.png', 'genre': 'Drama & Plays' },
{ 'id': 287, 'title': 'The Middle-Class Gentleman by Molière', 'image': '/the-middle-class-gentleman.png', 'genre': 'Drama & Plays' },
{ 'id': 288, 'title': 'The Miser by Molière', 'image': '/the-miser.png', 'genre': 'Drama & Plays' },
{ 'id': 289, 'title': 'The Man Who Was Thursday: A Nightmare by G. K. Chesterton', 'image': '/the-man-who-was-thursday-a-nightmare.png', 'genre': 'Drama & Plays' },
{ 'id': 290, 'title': 'The School for Scandal by Richard Brinsley Sheridan', 'image': '/the-school-for-scandal.png', 'genre': 'Drama & Plays' },
{ 'id': 291, 'title': 'The Sea Gull by Anton Chekhov', 'image': '/the-sea-gull.png', 'genre': 'Drama & Plays' },
{ 'id': 292, 'title': 'The Tragedy of King Lear by William Shakespeare', 'image': '/the-tragedy-of-king-lear.png', 'genre': 'Drama & Plays' },
{ 'id': 293, 'title': 'The Tragical History of Doctor Faustus by Christopher Marlowe', 'image': '/the-tragical-history-of-doctor-faustus.png', 'genre': 'Drama & Plays' },
{ 'id': 294, 'title': 'The Robbers by Friedrich Schiller', 'image': '/the-robbers.png', 'genre': 'Drama & Plays' },
{ 'id': 295, 'title': 'Twelfth Night; Or, What You Will by William Shakespeare', 'image': '/twelfth-night-or-what-you-will.png', 'genre': 'Drama & Plays' },
{ 'id': 296, 'title': 'Volpone; Or, The Fox by Ben Jonson', 'image': '/volpone-or-the-fox.png', 'genre': 'Drama & Plays' },
{ 'id': 297, 'title': 'Hobson\'s Choice: A Lancashire Comedy in Four Acts by Harold Brighouse', 'image': '/hobsons-choice-a-lancashire-comedy-in-four-acts.png', 'genre': 'Drama & Plays' },
{ 'id': 298, 'title': 'A Brief Account of the Destruction of the Indies by Bartolome de las Casas', 'image': '/a-brief-account-of-the-destruction-of-the-indies.png', 'genre': 'Historical' },
{ 'id': 299, 'title': 'A Short History of the World by H. G. Wells', 'image': '/a-short-history-of-the-world.png', 'genre': 'Historical' },
{ 'id': 300, 'title': 'An Account of Egypt by Herodotus', 'image': '/an-account-of-egypt.png', 'genre': 'Historical' },
{ 'id': 301, 'title': 'Agricola by Tacitus', 'image': '/agricola.png', 'genre': 'Historical' },
{ 'id': 302, 'title': 'Antiquities of the Jews by Flavius Josephus', 'image': '/antiquities-of-the-jews.png', 'genre': 'Historical' },
{ 'id': 303, 'title': 'After Life in Roman Paganism by Franz Cumont', 'image': '/after-life-in-roman-paganism.png', 'genre': 'Historical' },
{ 'id': 304, 'title': 'Boswell\'s Life of Johnson by James Boswell', 'image': '/boswells-life-of-johnson.png', 'genre': 'Historical' },
{ 'id': 305, 'title': 'An Old Babylonian Version of the Gilgamesh Epic by Morris Jastrow', 'image': '/the-epic-of-gilgamesh.png', 'genre': 'Historical' },
{ 'id': 306, 'title': 'Apologia Pro Vita Sua by John Henry Newman', 'image': '/apologia-pro-vita-sua.png', 'genre': 'Historical' },
{ 'id': 307, 'title': 'De Bello Gallico and Other Commentaries by Julius Caesar', 'image': '/de-bello-gallico-and-other-commentaries.png', 'genre': 'Historical' },
{ 'id': 308, 'title': 'Democracy in America Volume 1 by Alexis de Tocqueville', 'image': '/democracy-in-america-volume-1.png', 'genre': 'Historical' },
{ 'id': 309, 'title': 'Der Briefwechsel Zwischen Friedrich Engels und Karl Marx 1844 bis 1883 by Karl Marx & Friedrich Engels', 'image': '/der-briefwechsel-zwischen-friedrich-engels-und-karl-marx-1844-bis-1883.png', 'genre': 'Historical' },
{ 'id': 310, 'title': 'Fox\'s Book of Martyrs by John Foxe', 'image': '/foxs-book-of-martyrs.png', 'genre': 'Historical' },
{ 'id': 311, 'title': 'From Berlin to Bagdad and Babylon by F. M. A. V. Montagu', 'image': '/from-berlin-to-bagdad-and-babylon.png', 'genre': 'Historical' },
{ 'id': 312, 'title': 'Haida Texts and Myths by John Swanton', 'image': '/haida-texts-and-myths.png', 'genre': 'Historical' },
{ 'id': 313, 'title': 'La Chartreuse de Parme by Stendhal', 'image': '/la-chartreuse-de-parme.png', 'genre': 'Historical' },
{ 'id': 314, 'title': 'Manners, Customs, and Dress during the Middle Ages and during the Renaissance Period by Paul Lacroix', 'image': '/manners-customs-and-dress-during-the-middle-ages-and-during-the-renaissance-period.png', 'genre': 'Historical' },
{ 'id': 315, 'title': 'Martti Mestari ja Hänen Kisällinsä by Aleksis Kivi', 'image': '/martti-mestari-ja-hänen-kisällinsä.png', 'genre': 'Historical' },
{ 'id': 316, 'title': 'Memoirs of Napoleon Bonaparte Complete by Louis Antoine Fauvelet de Bourrienne', 'image': '/memoirs-of-napoleon-bonaparte-complete.png', 'genre': 'Historical' },
{ 'id': 317, 'title': 'Memoirs of the Court of Marie Antoinette, Queen of France, Complete by Madame Campan', 'image': '/memoirs-of-the-court-of-marie-antoinette-queen-of-france-complete.png', 'genre': 'Historical' },
{ 'id': 318, 'title': 'Narrative of the Life of Frederick Douglass, an American Slave by Frederick Douglass', 'image': '/narrative-of-the-life-of-frederick-douglass-an-american-slave.png', 'genre': 'Historical' },
{ 'id': 319, 'title': 'On Heroes, Hero-Worship, and the Heroic in History by Thomas Carlyle', 'image': '/on-heroes-hero-worship-and-the-heroic-in-history.png', 'genre': 'Historical' },
{ 'id': 320, 'title': 'Plutarch\'s Lives of the Noble Grecians and Romans by Plutarch', 'image': '/plutarch-s-lives-of-the-noble-grecians-and-romans.png', 'genre': 'Historical' },
{ 'id': 321, 'title': 'Quo Vadis: A Narrative of the Time of Nero by Henryk Sienkiewicz', 'image': '/quo-vadis-a-narrative-of-the-time-of-nero.png', 'genre': 'Historical' },
{ 'id': 322, 'title': 'Scaramouche: A Romance of the French Revolution by Rafael Sabatini', 'image': '/scaramouche-a-romance-of-the-french-revolution.png', 'genre': 'Historical' },
{ 'id': 323, 'title': 'Plutarch\'s Lives Volume 1 (of 4) by Plutarch', 'image': '/plutarchs-lives-volume-1-(of-4).png', 'genre': 'Historical' },
{ 'id': 324, 'title': 'Personal Memoirs of U.S. Grant (Complete) by Ulysses S. Grant', 'image': '/personal-memoirs-of-u-s-grant-(complete).png', 'genre': 'Historical' },
{ 'id': 325, 'title': 'The Anglo-Saxon Chronicle', 'image': '/the-anglo-saxon-chronicle.png', 'genre': 'Historical' },
{ 'id': 326, 'title': 'The Anabasis of Alexander', 'image': '/the-anabasis-of-alexander.png', 'genre': 'Historical' },
{ 'id': 327, 'title': 'The Danish History Books I-IX', 'image': '/the-danish-history-books-I-IX.png', 'genre': 'Historical' },
{ 'id': 328, 'title': 'The Germany and the Agricola of Tacitus', 'image': '/the-germany-and-the-agricola-of-tacitus.png', 'genre': 'Historical' },
{ 'id': 329, 'title': 'The French Revolution: A History', 'image': '/the-french-revolution-a-history.png', 'genre': 'Historical' },
{ 'id': 330, 'title': 'The History of Herodotus Volume 1', 'image': '/the-history-of-herodotus-volume-1.png', 'genre': 'Historical' },
{ 'id': 331, 'title': 'The History of the Decline and Fall of the Roman Empire by Edward Gibbon', 'image': '/the-history-of-the-decline-and-fall-of-the-roman-empire.png', 'genre': 'Historical' },
{ 'id': 332, 'title': 'The History of the Peloponnesian War by Thucydides', 'image': '/the-history-of-the-peloponnesian-war.png', 'genre': 'Historical' },
{ 'id': 333, 'title': 'The Magna Carta', 'image': '/the-magna-carta.png', 'genre': 'Historical' },
{ 'id': 334, 'title': 'The Mahabharata of Krishna-Dwaipayana Vyasa', 'image': '/the-mahabharata-of-krishna-dwaipayana-vyasa.png', 'genre': 'Historical' },
{ 'id': 335, 'title': 'The Journals of Lewis and Clark 1804-1806 by Meriwether Lewis and William Clark', 'image': '/the-journals-of-lewis-and-clark-1804-1806.png', 'genre': 'Historical' },
{ 'id': 336, 'title': 'The Story of Burnt Njal from the Icelandic of the Njal\'s Saga by George Webbe Dasent', 'image': '/the-story-of-burnt-njal-from-the-icelandic-of-the-njal-s-saga.png', 'genre': 'Historical' },
{ 'id': 337, 'title': 'The Wars of the Jews or The History of the Destruction of Jerusalem by Flavius Josephus', 'image': '/the-wars-of-the-jews-or-the-history-of-the-destruction-of-jerusalem.png', 'genre': 'Historical' },
{ 'id': 338, 'title': 'The Underground Railroad by William Still', 'image': '/the-underground-railroad.png', 'genre': 'Historical' },
{ 'id': 339, 'title': 'The Story of Mankind by Hendrik Willem van Loon', 'image': '/the-story-of-mankind.png', 'genre': 'Historical' },
{ 'id': 340, 'title': 'The Worst Journey in the World, Antarctic 1910-1913 by Apsley Cherry-Garrard', 'image': '/the-worst-journey-in-the-world-antarctic-1910-1913.png', 'genre': 'Historical' },
{ 'id': 341, 'title': 'War and Peace by Leo Tolstoy', 'image': '/war-and-peace.png', 'genre': 'Historical' },
{ 'id': 342, 'title': 'History of the Philippines', 'image': '/history-of-the-philippines.png', 'genre': 'Historical' },
{ 'id': 343, 'title': 'History of the World War: An Authentic Narrative of the World\'s Greatest War', 'image': '/history-of-the-world-war-an-authentic-narrative-of-the-worlds-greatest-war.png', 'genre': 'Historical' },
{ 'id': 344, 'title': 'A General Introduction to Psychoanalysis by Sigmund Freud', 'image': '/a-general-introduction-to-psychoanalysis.png', 'genre': 'Non-Fiction' },
{ 'id': 345, 'title': 'A System of Logic, Ratiocinative and Inductive by John Stuart Mill', 'image': '/a-system-of-logic-ratiocinative-and-inductive.png', 'genre': 'Non-Fiction' },
{ 'id': 346, 'title': 'An Introduction to Mathematics by Alfred North Whitehead', 'image': '/an-introduction-to-mathematics.png', 'genre': 'Non-Fiction' },
{ 'id': 347, 'title': 'Autobiography of a Yogi by Paramahansa Yogananda', 'image': '/autobiography-of-a-yogi.png', 'genre': 'Non-Fiction' },
{ 'id': 348, 'title': 'Autobiography of Benjamin Franklin by Benjamin Franklin', 'image': '/autobiography-of-benjamin-franklin.png', 'genre': 'Non-Fiction' },
{ 'id': 349, 'title': 'A First Spanish Reader by Angel Flores', 'image': '/a-first-spanish-reader.png', 'genre': 'Non-Fiction' },
{ 'id': 350, 'title': 'A Dictionary of Slang, Cant, and Vulgar Words by John S. Farmer', 'image': '/a-dictionary-of-slang-cant-and-vulgar-words.png', 'genre': 'Non-Fiction' },
{ 'id': 351, 'title': 'Aeneidos by Virgil', 'image': '/aeneidos.png', 'genre': 'Non-Fiction' },
{ 'id': 352, 'title': 'An Investigation of the Laws of Thought by George Boole', 'image': '/an-investigation-of-the-laws-of-thought.png', 'genre': 'Non-Fiction' },
{ 'id': 353, 'title': 'An Inquiry into the Nature and Causes of the Wealth of Nations by Adam Smith', 'image': '/an-inquiry-into-the-nature-and-causes-of-the-wealth-of-nations.png', 'genre': 'Non-Fiction' },
{ 'id': 354, 'title': 'An Account of Egypt by Herodotus', 'image': '/an-account-of-egypt.png', 'genre': 'Non-Fiction' },
{ 'id': 355, 'title': 'Bacon\'s Essays and Wisdom of the Ancients by Francis Bacon', 'image': '/bacons-essays-and-wisdom-of-the-ancients.png', 'genre': 'Non-Fiction' },
{ 'id': 356, 'title': 'Beautiful Shells by Various Authors', 'image': '/beautiful-shells.png', 'genre': 'Non-Fiction' },
{ 'id': 357, 'title': 'Bypaths in Dixie by Sarah Johnson Cocke', 'image': '/bypaths-in-dixie.png', 'genre': 'Non-Fiction' },
{ 'id': 358, 'title': 'Boy Scouts Handbook by Boy Scouts of America', 'image': '/boy-scouts-handbook.png', 'genre': 'Non-Fiction' },
{ 'id': 359, 'title': 'Calculus Made Easy by Silvanus P. Thompson', 'image': '/calculus-made-easy.png', 'genre': 'Non-Fiction' },
{ 'id': 360, 'title': 'Children\'s Books and Their Illustrators by Gleeson White', 'image': '/childrens-books-and-their-illustrators.png', 'genre': 'Non-Fiction' },
{ 'id': 361, 'title': 'Death and After by Annie Besant', 'image': '/death-and-after.png', 'genre': 'Non-Fiction' },
{ 'id': 362, 'title': 'Diccionario Ingles-Espanol-Tagalog by Sofronio G. Calderon', 'image': '/diccionario-ingles-espanol-tagalog.png', 'genre': 'Non-Fiction' },
{ 'id': 363, 'title': 'Doctrina Christiana by Anonymous', 'image': '/doctrina-christiana.png', 'genre': 'Non-Fiction' },
{ 'id': 364, 'title': 'Dream Psychology: Psychoanalysis for Beginners by Sigmund Freud', 'image': '/dream-psychology-psychoanalysis-for-beginners.png', 'genre': 'Non-Fiction' },
{ 'id': 365, 'title': 'Experimental Researches in Electricity, Volume 1 by Michael Faraday', 'image': '/experimental-researches-in-electricity-volume-1.png', 'genre': 'Non-Fiction' },
{ 'id': 366, 'title': 'Garden Cities of To-Morrow by Ebenezer Howard', 'image': '/garden-cities-of-to-morrow.png', 'genre': 'Non-Fiction' },
{ 'id': 367, 'title': 'Grace Abounding to the Chief of Sinners by John Bunyan', 'image': '/grace-abounding-to-the-chief-of-sinners.png', 'genre': 'Non-Fiction' },
{ 'id': 368, 'title': 'Gypsy Sorcery and Fortune Telling by Charles Godfrey Leland', 'image': '/gypsy-sorcery-and-fortune-telling.png', 'genre': 'Non-Fiction' },
{ 'id': 369, 'title': 'Helps to Latin Translation at Sight by Edmund Luce', 'image': '/helps-to-latin-translation-at-sight.png', 'genre': 'Non-Fiction' },
{ 'id': 370, 'title': 'Harriss List of Covent Garden Ladies by Anonymous', 'image': '/harriss-list-of-covent-garden-ladies.png', 'genre': 'Non-Fiction' },
{ 'id': 371, 'title': 'Japanese Girls and Women by Alice Mabel Bacon', 'image': '/japanese-girls-and-women.png', 'genre': 'Non-Fiction' },
{ 'id': 372, 'title': 'Korean-English Dictionary by Various Authors', 'image': '/korean-english-dictionary.png', 'genre': 'Non-Fiction' },
{ 'id': 373, 'title': 'Little Masterpieces of Science: Mind by Various Authors', 'image': '/little-masterpieces-of-science-mind.png', 'genre': 'Non-Fiction' },
{ 'id': 374, 'title': 'Mein Kampf (My Struggle) by Adolf Hitler', 'image': '/mein-kampf-my-struggle.png', 'genre': 'Non-Fiction' },
{ 'id': 375, 'title': 'Memoirs of Fanny Hill by John Cleland', 'image': '/memoirs-of-fanny-hill.png', 'genre': 'Non-Fiction' },
{ 'id': 376, 'title': 'My Bondage and My Freedom by Frederick Douglass', 'image': '/my-bondage-and-my-freedom.png', 'genre': 'Non-Fiction' },
{ 'id': 377, 'title': 'My Life Volume 1 by Richard Wagner', 'image': '/my-life-volume-1.png', 'genre': 'Non-Fiction' },
{ 'id': 378, 'title': 'My Past is Mine by C. R. Boxer', 'image': '/my-past-is-mine.png', 'genre': 'Non-Fiction' },
{ 'id': 379, 'title': 'My Further Disillusionment by Emma Goldman', 'image': '/my-further-disillusionment.png', 'genre': 'Non-Fiction' },
{ 'id': 380, 'title': 'Novo Dicionário by Various Authors', 'image': '/novo-dicionario.png', 'genre': 'Non-Fiction' },
{ 'id': 381, 'title': 'Letters of Pliny by Pliny the Younger', 'image': '/letters-of-pliny.png', 'genre': 'Non-Fiction' },
{ 'id': 382, 'title': 'Letters to His Son Complete by Philip Stanhope', 'image': '/letters-to-his-son-complete.png', 'genre': 'Non-Fiction' },
{ 'id': 383, 'title': 'On the Origin of Species by Charles Darwin', 'image': '/on-the-origin-of-species.png', 'genre': 'Non-Fiction' },
{ 'id': 384, 'title': 'Philosophiae Naturalis Principia Mathematica by Isaac Newton', 'image': '/philosophiae-naturalis-principia-mathematica.png', 'genre': 'Non-Fiction' },
{ 'id': 385, 'title': 'Pennsylvania Dutch Cooking by Unknown', 'image': '/pennsylvania-dutch-cooking.png', 'genre': 'Non-Fiction' },
{ 'id': 386, 'title': 'Palmistry for All by Cheiro', 'image': '/palmistry-for-all.png', 'genre': 'Non-Fiction' },
{ 'id': 387, 'title': 'Roget\'s Thesaurus of English Words and Phrases by Peter Mark Roget', 'image': '/rogets-thesaurus-of-english-words-and-phrases.png', 'genre': 'Non-Fiction' },
{ 'id': 388, 'title': 'Relativity: The Special and General Theory by Albert Einstein', 'image': '/relativity-the-special-and-general-theory.png', 'genre': 'Non-Fiction' },
{ 'id': 389, 'title': 'Texas Fossils: An Amateur Collector\'s Handbook by Unknown', 'image': '/texas-fossils-an-amateur-collectors-handbook.png', 'genre': 'Non-Fiction' },
{ 'id': 390, 'title': 'Texas Rocks and Minerals: An Amateur\'s Guide by Unknown', 'image': '/texas-rocks-and-minerals-an-amateurs-guide.png', 'genre': 'Non-Fiction' },
{ 'id': 391, 'title': 'The Art of Money Getting', 'image': '/the-art-of-money-getting.png', 'genre': 'Non-Fiction' },
{ 'id': 392, 'title': 'The Communist Manifesto', 'image': '/the-communist-manifesto.png', 'genre': 'Non-Fiction' },
{ 'id': 393, 'title': 'The Economic Consequences of the Peace', 'image': '/the-economic-consequences-of-the-peace.png', 'genre': 'Non-Fiction' },
{ 'id': 394, 'title': 'The Elements of Style', 'image': '/the-elements-of-style.png', 'genre': 'Non-Fiction' },
{ 'id': 395, 'title': 'The Great Controversy Between Christ and Satan', 'image': '/the-great-controversy-between-christ-and-satan.png', 'genre': 'Non-Fiction' },
{ 'id': 396, 'title': 'The Jewish State by Theodor Herzl', 'image': '/the-jewish-state.png', 'genre': 'Non-Fiction' },
{ 'id': 397, 'title': 'The Journals of Lewis and Clark 1804-1806 by Meriwether Lewis and William Clark', 'image': '/the-journals-of-lewis-and-clark-1804-1806.png', 'genre': 'Non-Fiction' },
{ 'id': 398, 'title': 'The Principles of Economics with Applications to Practical Problems by Frank A. Fetter', 'image': '/the-principles-of-economics-with-applications-to-practical-problems.png', 'genre': 'Non-Fiction' },
{ 'id': 399, 'title': 'The Life of the Spider by J. Henri Fabre', 'image': '/the-life-of-the-spider.png', 'genre': 'Non-Fiction' },
{ 'id': 400, 'title': 'The Meaning of Relativity by Albert Einstein', 'image': '/the-meaning-of-relativity.png', 'genre': 'Non-Fiction' },
{ 'id': 401, 'title': 'The Problems of Philosophy by Bertrand Russell', 'image': '/the-problems-of-philosophy.png', 'genre': 'Non-Fiction' },
{ 'id': 402, 'title': 'The Natural History of Pliny Volume 1 (of 6) by Pliny the Elder', 'image': '/the-natural-history-of-pliny-volume-1-(of-6).png', 'genre': 'Non-Fiction' },
{ 'id': 403, 'title': 'The Souls of Black Folk by W.E.B. Du Bois', 'image': '/the-souls-of-black-folk.png', 'genre': 'Non-Fiction' },
{ 'id': 404, 'title': 'The Science of Getting Rich by Wallace D. Wattles', 'image': '/the-science-of-getting-rich.png', 'genre': 'Non-Fiction' },
{ 'id': 405, 'title': 'The Story of My Life: Complete by Helen Keller', 'image': '/the-story-of-my-life-complete.png', 'genre': 'Non-Fiction' },
{ 'id': 406, 'title': 'The Theory of the Leisure Class by Thorstein Veblen', 'image': '/the-theory-of-the-leisure-class.png', 'genre': 'Non-Fiction' },
{ 'id': 407, 'title': 'The Seven Lamps of Architecture by John Ruskin', 'image': '/the-seven-lamps-of-architecture.png', 'genre': 'Non-Fiction' },
{ 'id': 408, 'title': 'The Whitehouse Cookbook (1887) by Mrs. F.L. Gillette and Hugo Ziemann', 'image': '/the-whitehouse-cookbook-(1887).png', 'genre': 'Non-Fiction' },
{ 'id': 409, 'title': 'Thought-Forms by Annie Besant and C. W. Leadbeater', 'image': '/thought-forms.png', 'genre': 'Non-Fiction' },
{ 'id': 410, 'title': 'Woman in the Nineteenth Century by Margaret Fuller', 'image': '/woman-in-the-nineteenth-century.png', 'genre': 'Non-Fiction' },
{ 'id': 411, 'title': 'Woodworking Tools 1600–1900 by Peter C. Welsh', 'image': '/woodworking-tools-1600-1900.png', 'genre': 'Non-Fiction' },
{ 'id': 412, 'title': 'How to Analyze People on Sight by Elsie Lincoln Benedict and Ralph Paine Benedict', 'image': '/how-to-analyze-people-on-sight.png', 'genre': 'Non-Fiction' },
{ 'id': 413, 'title': 'How to Live on 24 Hours a Day by Arnold Bennett', 'image': '/how-to-live-on-24-hours-a-day.png', 'genre': 'Non-Fiction' },
{ 'id': 414, 'title': 'A Connecticut Yankee in King Arthur\'s Court by Mark Twain', 'image': '/a-connecticut-yankee-in-king-arthurs-court.png', 'genre': 'Adventure' },
{ 'id': 415, 'title': 'Around the World in Eighty Days by Jules Verne', 'image': '/around-the-world-in-eighty-days.png', 'genre': 'Adventure' },
{ 'id': 416, 'title': 'Adventures of Huckleberry Finn by Mark Twain', 'image': '/adventures-of-huckleberry.png', 'genre': 'Adventure' },
{ 'id': 417, 'title': 'Treasure Island by Robert Louis Stevenson', 'image': '/treasure-island.png', 'genre': 'Adventure' },
{ 'id': 418, 'title': 'Captain Blood by Rafael Sabatini', 'image': '/captain-blood.png', 'genre': 'Adventure' },
{ 'id': 419, 'title': 'Brewster\'s Millions by George Barr McCutcheon', 'image': '/brewsters-millions.png', 'genre': 'Adventure' },
{ 'id': 420, 'title': 'A Journey Into the Interior of the Earth by Jules Verne', 'image': '/a-journey-into-the-interior-of-the-earth.png', 'genre': 'Adventure' },
{ 'id': 421, 'title': 'A Voyage to Arcturus by David Lindsay', 'image': '/a-voyage-to-arcturus.png', 'genre': 'Adventure' },
{ 'id': 422, 'title': 'A Princess of Mars by Edgar Rice Burroughs', 'image': '/a-princess-of-mars.png', 'genre': 'Adventure' },
{ 'id': 423, 'title': 'Gulliver\'s Travels by Jonathan Swift', 'image': '/gullivers-travels.png', 'genre': 'Adventure' },
{ 'id': 424, 'title': 'Five Children and It by E. Nesbit', 'image': '/five-children-and-it.png', 'genre': 'Adventure' },
{ 'id': 425, 'title': 'Don Quixote by Miguel de Cervantes Saavedra', 'image': '/don-quixote.png', 'genre': 'Adventure' },
{ 'id': 426, 'title': 'Following the Equator: A Journey Around the World by Mark Twain', 'image': '/following-the-equator-a-journey-around-the-world.png', 'genre': 'Adventure' },
{ 'id': 427, 'title': 'Golden Fleece: The American Adventures of a Fortune Hunting Earl by David Graham Phillips', 'image': '/golden-fleece-the-american-adventures-of-a-fortune-hunting-earl.png', 'genre': 'Adventure' },
{ 'id': 428, 'title': 'King Solomon\'s Mines by H. Rider Haggard', 'image': '/king-solomons-mines.png', 'genre': 'Adventure' },
{ 'id': 429, 'title': 'Kidnapped by Robert Louis Stevenson', 'image': '/kidnapped.png', 'genre': 'Adventure' },
{ 'id': 430, 'title': 'L\'Île Mystérieuse by Jules Verne', 'image': '/lile-mysterieuse.png', 'genre': 'Adventure' },
{ 'id': 431, 'title': 'Moby Dick by Herman Melville', 'image': '/moby-dick.png', 'genre': 'Adventure' },
{ 'id': 432, 'title': 'Martti Mestari ja Hänen Kisällinsä by Aleksis Kivi', 'image': '/martti-mestari-ja-hänen-kisällinsä.png', 'genre': 'Adventure' },
{ 'id': 433, 'title': 'King Lear by William Shakespeare', 'image': '/king-lear.png', 'genre': 'Adventure' },
{ 'id': 434, 'title': 'Swiss Family Robinson by Johann David Wyss', 'image': '/swiss-family-robinson.png', 'genre': 'Adventure' },
{ 'id': 435, 'title': 'Oliver Twist by Charles Dickens', 'image': '/oliver-twist.png', 'genre': 'Adventure' },
{ 'id': 436, 'title': 'She by H. Rider Haggard', 'image': '/she.png', 'genre': 'Adventure' },
{ 'id': 437, 'title': 'Sailing Alone Around the World by Joshua Slocum', 'image': '/sailing-alone-around-the-world.png', 'genre': 'Adventure' },
{ 'id': 438, 'title': 'Ragged Dick, or, Street Life in New York with the Boot Blacks by Horatio Alger', 'image': '/ragged-dick-or-street-life-in-new-york-with-the-boot-blacks.png', 'genre': 'Adventure' },
{ 'id': 439, 'title': 'The Adventures of Tom Sawyer', 'image': '/the-adventures-of-tom-sawyer.png', 'genre': 'Adventure' },
{ 'id': 440, 'title': 'The Further Adventures of Robinson Crusoe', 'image': '/the-further-adventures-of-robinson-crusoe.png', 'genre': 'Adventure' },
{ 'id': 441, 'title': 'The Call of the Wild', 'image': '/the-call-of-the-wild.png', 'genre': 'Adventure' },
{ 'id': 442, 'title': 'The Adventures of Ferdinand', 'image': '/the-adventures-of-ferdinand.png', 'genre': 'Adventure' },
{ 'id': 443, 'title': 'The Island of Doctor Moreau by H.G. Wells', 'image': '/the-island-of-doctor-moreau.png', 'genre': 'Adventure' },
{ 'id': 444, 'title': 'The Princess and the Goblin by George MacDonald', 'image': '/the-princess-and-the-goblin.png', 'genre': 'Adventure' },
{ 'id': 445, 'title': 'The Hound of the Baskervilles by Arthur Conan Doyle', 'image': '/the-hound-of-the-baskervilles.png', 'genre': 'Adventure' },
{ 'id': 446, 'title': 'The Man with Two Left Feet and Other Stories by P. G. Wodehouse', 'image': '/the-man-with-two-left-feet-and-other-stories.png', 'genre': 'Adventure' },
{ 'id': 447, 'title': 'The Open Boat and Other Stories by Stephen Crane', 'image': '/the-open-boat-and-other-stories.png', 'genre': 'Adventure' },
{ 'id': 448, 'title': 'The Sea-Wolf by Jack London', 'image': '/the-sea-wolf.png', 'genre': 'Adventure' },
{ 'id': 449, 'title': 'The Scarlet Pimpernel by Baroness Orczy', 'image': '/the-scarlet-pimpernel.png', 'genre': 'Adventure' },
{ 'id': 450, 'title': 'The Secret Garden by Frances Hodgson Burnett', 'image': '/the-secret-garden.png', 'genre': 'Adventure' },
{ 'id': 451, 'title': 'The Scarlet Plague by Jack London', 'image': '/the-scarlet-plague.png', 'genre': 'Adventure' },
{ 'id': 452, 'title': 'A Room with a View by E. M. Forster', 'image': '/a-room-with-a-view.png', 'genre': 'Romance' },
{ 'id': 453, 'title': 'Anna Karenina by Leo Tolstoy', 'image': '/anna-karenina.png', 'genre': 'Romance' },
{ 'id': 454, 'title': 'Anne of Green Gables by L. M. Montgomery', 'image': '/anne-of-green-gables.png', 'genre': 'Romance' },
{ 'id': 455, 'title': 'Anne of the Island by L. M. Montgomery', 'image': '/anne-of-the-island.png', 'genre': 'Romance' },
{ 'id': 456, 'title': 'Barchester Towers by Anthony Trollope', 'image': '/barchester-towers.png', 'genre': 'Romance' },
{ 'id': 457, 'title': 'A Sentimental Journey Through France and Italy by Laurence Sterne', 'image': '/a-sentimental-journey-through-france-and-italy.png', 'genre': 'Romance' },
{ 'id': 458, 'title': 'A Hero of Our Time by Mikhail Lermontov', 'image': '/a-hero-of-our-time.png', 'genre': 'Romance' },
{ 'id': 459, 'title': 'Ars Amatoria or The Art of Love by Ovid', 'image': '/ars-amatoria-or-the-art-of-love.png', 'genre': 'Romance' },
{ 'id': 460, 'title': 'Clarissa Harlowe; or, The History of a Young Lady, Volume 1 by Samuel Richardson', 'image': '/clarissa-harlowe-or-the-history-of-a-young-lady-volume-1.png', 'genre': 'Romance' },
{ 'id': 461, 'title': 'Dangerous Connections V 1, 2, 3, 4 by Pierre Choderlos de Laclos', 'image': '/dangerous-connections-v-1,-2,-3,-4.png', 'genre': 'Romance' },
{ 'id': 462, 'title': 'Emma by Jane Austen', 'image': '/emma.png', 'genre': 'Romance' },
{ 'id': 463, 'title': 'Eugene Oneguine [Onegin] by Alexander Pushkin', 'image': '/eugene-oneguine-[onegin].png', 'genre': 'Romance' },
{ 'id': 464, 'title': 'Evelina, Or the History of a Young Lady\'s Entrance into the World by Fanny Burney', 'image': '/evelina-or-the-history-of-a-young-ladys-entrance-into-the-world.png', 'genre': 'Romance' },
{ 'id': 465, 'title': 'Frau Bovary by Gustave Flaubert', 'image': '/frau-bovary.png', 'genre': 'Romance' },
{ 'id': 466, 'title': 'Demian by Hermann Hesse', 'image': '/demian.png', 'genre': 'Romance' },
{ 'id': 467, 'title': 'Lady Susan by Jane Austen', 'image': '/lady-susan.png', 'genre': 'Romance' },
{ 'id': 468, 'title': 'Love and Freindship by Jane Austen', 'image': '/love-and-freindship.png', 'genre': 'Romance' },
{ 'id': 469, 'title': 'Jude the Obscure by Thomas Hardy', 'image': '/jude-the-obscure.png', 'genre': 'Romance' },
{ 'id': 470, 'title': 'Madame Bovary by Gustave Flaubert', 'image': '/madame-bovary.png', 'genre': 'Romance' },
{ 'id': 471, 'title': 'Persuasion by Jane Austen', 'image': '/persuasion.png', 'genre': 'Romance' },
{ 'id': 472, 'title': 'Pride and Prejudice by Jane Austen', 'image': '/pride-and-prejudice.png', 'genre': 'Romance' },
{ 'id': 473, 'title': 'Romeo and Juliet by William Shakespeare', 'image': '/romeo-juliet.png', 'genre': 'Romance' },
{ 'id': 474, 'title': 'Sense and Sensibility by Jane Austen', 'image': '/sense-and-sensibility.png', 'genre': 'Romance' },
{ 'id': 475, 'title': 'Queen Lucia by E. F. Benson', 'image': '/queen-lucia.png', 'genre': 'Romance' },
{ 'id': 476, 'title': 'She by H. Rider Haggard', 'image': '/she.png', 'genre': 'Romance' },
{ 'id': 477, 'title': 'Tess of the d\'Urbervilles by Thomas Hardy', 'image': '/tess-of-the-d-urbervilles.png', 'genre': 'Romance' },
{ 'id': 478, 'title': 'The Blue Castle', 'image': '/the-blue-castle.png', 'genre': 'Romance' },
{ 'id': 479, 'title': 'The Gift of the Magi', 'image': '/the-gift-of-the-magi.png', 'genre': 'Romance' },
{ 'id': 480, 'title': 'The Great Gatsby', 'image': '/the-great-gatsby.png', 'genre': 'Romance' },
{ 'id': 481, 'title': 'The Kreutzer Sonata and Other Stories by Leo Tolstoy', 'image': '/the-kreutzer-sonata-and-other-stories.png', 'genre': 'Romance' },
{ 'id': 482, 'title': 'The Lady of the Lake by Sir Walter Scott', 'image': '/the-lady-of-the-lake.png', 'genre': 'Romance' },
{ 'id': 483, 'title': 'The Moon and Sixpence by W. Somerset Maugham', 'image': '/the-moon-and-sixpence.png', 'genre': 'Romance' },
{ 'id': 484, 'title': 'The Romance of Tristan and Iseult by Joseph Bédier', 'image': '/the-romance-of-tristan-and-iseult.png', 'genre': 'Romance' },
{ 'id': 485, 'title': 'The Rainbow by D. H. Lawrence', 'image': '/the-rainbow.png', 'genre': 'Romance' },
{ 'id': 486, 'title': 'The Scarlet Letter by Nathaniel Hawthorne', 'image': '/the-scarlet-letter.png', 'genre': 'Romance' },
{ 'id': 487, 'title': 'Jane Eyre by Charlotte Brontë', 'image': '/jane-eyre.png', 'genre': 'Romance' },
{ 'id': 488, 'title': 'Wuthering Heights by Emily Brontë', 'image': '/wuthering-heights.png', 'genre': 'Romance' },
{ 'id': 489, 'title': 'Zuleika Dobson or an Oxford Love Story by Max Beerbohm', 'image': '/zuleika-dobson-or-an-oxford-love-story.png', 'genre': 'Romance' },
{ 'id': 490, 'title': 'A Shropshire Lad by A. E. Housman', 'image': '/a-shropshire-lad.png', 'genre': 'Poetry' },
{ 'id': 491, 'title': 'Ancient Nahuatl Poetry by Daniel G. Brinton', 'image': '/ancient-nahuatl-poetry.png', 'genre': 'Poetry' },
{ 'id': 492, 'title': 'Childe Harold\'s Pilgrimage by Lord Byron', 'image': '/childe-harolds-pilgrimage.png', 'genre': 'Poetry' },
{ 'id': 493, 'title': 'Beowulf by Anonymous', 'image': '/beowulf-an-anglo-saxon-epic-poem.png', 'genre': 'Poetry' },
{ 'id': 494, 'title': 'Chaucer\'s Works, Volume 4 by Geoffrey Chaucer', 'image': '/chaucers-works-volume-4.png', 'genre': 'Poetry' },
{ 'id': 495, 'title': 'Chaucer for Children: A Golden Key by Geoffrey Chaucer', 'image': '/chaucer-for-children-a-golden-key.png', 'genre': 'Poetry' },
{ 'id': 496, 'title': 'Aeneidos by Virgil', 'image': '/aeneidos.png', 'genre': 'Poetry' },
{ 'id': 497, 'title': 'Ars Amatoria or The Art of Love by Ovid', 'image': '/ars-amatoria-or-the-art-of-love.png', 'genre': 'Poetry' },
{ 'id': 498, 'title': 'Andersen\'s Fairy Tales by Hans Christian Andersen', 'image': '/andersen-s-fairy-tales.png', 'genre': 'Poetry' },
{ 'id': 499, 'title': 'Divine Comedy, Longfellow Translation, Hell by Dante Alighieri', 'image': '/divine-comedy-longfellow-translation-hell.png', 'genre': 'Poetry' },
{ 'id': 500, 'title': 'Don Juan by Lord Byron', 'image': '/don-juan.png', 'genre': 'Poetry' },
{ 'id': 502, 'title': 'Fables of La Fontaine, A New Edition with Notes by Jean de La Fontaine', 'image': '/fables-of-la-fontaine.png', 'genre': 'Poetry' },
{ 'id': 503, 'title': 'Fifteen Sonnets of Petrarch by Francesco Petrarca', 'image': '/fifteen-sonnets-of-petrarch.png', 'genre': 'Poetry' },
{ 'id': 504, 'title': 'Eugene Oneguine [Onegin] by Alexander Pushkin', 'image': '/eugene-oneguine-[onegin].png', 'genre': 'Poetry' },
{ 'id': 505, 'title': 'Leaves of Grass by Walt Whitman', 'image': '/leaves-of-grass.png', 'genre': 'Poetry' },
{ 'id': 506, 'title': 'Les Fleurs du Mal by Charles Baudelaire', 'image': '/les-fleurs-du-mal.png', 'genre': 'Poetry' },
{ 'id': 507, 'title': 'Mi Último Adiós by José Rizal', 'image': '/mi-ultimo-adios.png', 'genre': 'Poetry' },
{ 'id': 508, 'title': 'Le Roman de la Rose Tome I by Guillaume de Lorris', 'image': '/le-roman-de-la-rose-tome-i.png', 'genre': 'Poetry' },
{ 'id': 509, 'title': 'Marmion: A Tale of Flodden Field by Sir Walter Scott', 'image': '/marmion-a-a-tale-of-flodden-field.png', 'genre': 'Poetry' },
{ 'id': 511, 'title': 'Poems Every Child Should Know by Various', 'image': '/poems-every-child-should-know.png', 'genre': 'Poetry' },
{ 'id': 512, 'title': 'Poems with the Ballad of Reading Gaol by Oscar Wilde', 'image': '/poems-with-the-ballad-of-reading-gaol.png', 'genre': 'Poetry' },
{ 'id': 513, 'title': 'Prufrock and Other Observations by T. S. Eliot', 'image': '/prufrock-and-other-observations.png', 'genre': 'Poetry' },
{ 'id': 514, 'title': 'Spoon River Anthology by Edgar Lee Masters', 'image': '/spoon-river-anthology.png', 'genre': 'Poetry' },
{ 'id': 515, 'title': 'Spencer\'s The Faerie Queene Book 1 by Edmund Spenser', 'image': '/spencers-the-faerie-queene-book-1.png', 'genre': 'Poetry' },
{ 'id': 516, 'title': 'The Complete Poetical Works of Edgar Allan Poe', 'image': '/the-complete-poetical-works-of-edgar-allan-poe.png', 'genre': 'Poetry' },
{ 'id': 517, 'title': 'The Flowers of Evil', 'image': '/the-flowers-of-evil.png', 'genre': 'Poetry' },
{ 'id': 518, 'title': 'The Congo and Other Poems', 'image': '/the-congo-and-other-poems.png', 'genre': 'Poetry' },
{ 'id': 519, 'title': 'The Poems of Giacomo Leopardi by Giacomo Leopardi', 'image': '/the-poems-of-giacomo-leopardi.png', 'genre': 'Poetry' },
{ 'id': 520, 'title': 'The Odes of Casimire Translated by G. Hils', 'image': '/the-odes-of-casimire-translated-by-g-hils.png', 'genre': 'Poetry' },
{ 'id': 521, 'title': 'The Marriage of Heaven and Hell by William Blake', 'image': '/the-marriage-of-heaven-and-hell.png', 'genre': 'Poetry' },
{ 'id': 522, 'title': 'The Pied Piper of Hamelin by Robert Browning', 'image': '/the-pied-piper-of-hamelin.png', 'genre': 'Poetry' },
{ 'id': 523, 'title': 'The Raven by Edgar Allan Poe', 'image': '/the-raven.png', 'genre': 'Poetry' },
{ 'id': 524, 'title': 'The Task and Other Poems by William Cowper', 'image': '/the-task-and-other-poems.png', 'genre': 'Poetry' },
{ 'id': 525, 'title': 'The Works of Edgar Allan Poe by Edgar Allan Poe', 'image': '/the-works-of-edgar-allan-poe-volume-1.png', 'genre': 'Poetry' },
{ 'id': 527, 'title': 'Young\'s Night Thoughts by Edward Young', 'image': '/youngs-night-thoughts.png', 'genre': 'Poetry' },
{ 'id': 528, 'title': 'Hymen by H.D. (Hilda Doolittle)', 'image': '/hymen.png', 'genre': 'Poetry' },
{ 'id': 529, 'title': 'Idylls of the King by Alfred, Lord Tennyson', 'image': '/idylls-of-the-king.png', 'genre': 'Poetry' },
{ 'id': 530, 'title': 'Alice\'s Adventures in Wonderland by Lewis Carroll', 'image': '/alices-adventures-in-wonderland.png', 'genre': 'Children\'s Literature' },
{ 'id': 531, 'title': 'Anne of Green Gables by L. M. Montgomery', 'image': '/anne-of-green-gables.png', 'genre': 'Children\'s Literature' },
{ 'id': 532, 'title': 'Andersen\'s Fairy Tales by Hans Christian Andersen', 'image': '/andersen-s-fairy-tales.png', 'genre': 'Children\'s Literature' },
{ 'id': 533, 'title': 'Chaucer for Children: A Golden Key by Geoffrey Chaucer', 'image': '/chaucer-for-children-a-golden-key.png', 'genre': 'Children\'s Literature' },
{ 'id': 534, 'title': 'A Child\'s Dream of a Star by Charles Dickens', 'image': '/a-childs-dream-of-a-star.png', 'genre': 'Children\'s Literature' },
{ 'id': 535, 'title': 'Beautiful Shells by Various Authors', 'image': '/beautiful-shells.png', 'genre': 'Children\'s Literature' },
{ 'id': 536, 'title': 'Daddy Takes Us to the Garden by Howard R. Garis', 'image': '/daddy-takes-us-to-the-garden.png', 'genre': 'Children\'s Literature' },
{ 'id': 537, 'title': 'Der Struwwelpeter by Heinrich Hoffmann', 'image': '/der-struwwelpeter.png', 'genre': 'Children\'s Literature' },
{ 'id': 538, 'title': 'Fairy Tales of Hans Christian Andersen by Hans Christian Andersen', 'image': '/fairy-tales-of-hans-christian-andersen.png', 'genre': 'Children\'s Literature' },
{ 'id': 539, 'title': 'Five Children and It by E. Nesbit', 'image': '/five-children-and-it.png', 'genre': 'Children\'s Literature' },
{ 'id': 540, 'title': 'Favorite Fairy Tales by Various Authors', 'image': '/favorite-fairy-tales.png', 'genre': 'Children\'s Literature' },
{ 'id': 541, 'title': 'Grimm\'s Fairy Tales by The Brothers Grimm', 'image': '/grimms-fairy-tales.png', 'genre': 'Children\'s Literature' },
{ 'id': 542, 'title': 'Just So Stories by Rudyard Kipling', 'image': '/just-so-stories.png', 'genre': 'Children\'s Literature' },
{ 'id': 543, 'title': 'Just William by Richmal Crompton', 'image': '/just-william.png', 'genre': 'Children\'s Literature' },
{ 'id': 544, 'title': 'Little Black Sambo by Helen Bannerman', 'image': '/little-black-sambo.png', 'genre': 'Children\'s Literature' },
{ 'id': 545, 'title': 'McGuffey\'s First Eclectic Reader Revised Edition by William Holmes McGuffey', 'image': '/mcguffeys-first-eclectic-reader-revised-edition.png', 'genre': 'Children\'s Literature' },
{ 'id': 546, 'title': 'Millions of Cats by Wanda Gág', 'image': '/millions-of-cats.png', 'genre': 'Children\'s Literature' },
{ 'id': 547, 'title': 'Japanese Fairy Tales by Yei Theodora Ozaki', 'image': '/japanese-fairy-tales.png', 'genre': 'Children\'s Literature' },
{ 'id': 548, 'title': 'Peter Pan by J. M. Barrie', 'image': '/peter-pan.png', 'genre': 'Children\'s Literature' },
{ 'id': 549, 'title': 'Pollyanna by Eleanor H. Porter', 'image': '/pollyanna.png', 'genre': 'Children\'s Literature' },
{ 'id': 550, 'title': 'Old Granny Fox by Thornton W. Burgess', 'image': '/old-granny-fox.png', 'genre': 'Children\'s Literature' },
{ 'id': 551, 'title': 'Poems Every Child Should Know by Various', 'image': '/poems-every-child-should-know.png', 'genre': 'Children\'s Literature' },
{ 'id': 552, 'title': 'The Box-Car Children', 'image': '/the-box-car-children.png', 'genre': 'Children\'s Literature' },
{ 'id': 553, 'title': 'The Book of Dragons', 'image': '/the-book-of-dragons.png', 'genre': 'Children\'s Literature' },
{ 'id': 554, 'title': 'The Jungle Book by Rudyard Kipling', 'image': '/the-jungle-book.png', 'genre': 'Children\'s Literature' },
{ 'id': 555, 'title': 'The Princess and the Goblin by George MacDonald', 'image': '/the-princess-and-the-goblin.png', 'genre': 'Children\'s Literature' },
{ 'id': 556, 'title': 'The Happy Prince and Other Tales', 'image': '/the-happy-prince-and-other-tales.png', 'genre': 'Children\'s Literature' },
{ 'id': 557, 'title': 'The Railway Children by E. Nesbit', 'image': '/the-railway-children.png', 'genre': 'Children\'s Literature' },
{ 'id': 558, 'title': 'The Secret Garden by Frances Hodgson Burnett', 'image': '/the-secret-garden.png', 'genre': 'Children\'s Literature' },
{ 'id': 559, 'title': 'The Tale of Peter Rabbit by Beatrix Potter', 'image': '/the-tale-of-peter-rabbit.png', 'genre': 'Children\'s Literature' },
{ 'id': 560, 'title': 'The Tale of Tom Kitten by Beatrix Potter', 'image': '/the-tale-of-tom-kitten.png', 'genre': 'Children\'s Literature' },
{ 'id': 561, 'title': 'The Wonderful Wizard of Oz by L. Frank Baum', 'image': '/the-wonderful-wizard-of-oz.png', 'genre': 'Children\'s Literature' },
{ 'id': 562, 'title': 'Winnie the Pooh by A. A. Milne', 'image': '/winnie-the-pooh.png', 'genre': 'Children\'s Literature' },
{ 'id': 563, 'title': 'Uncle Remus and Brer Rabbit by Joel Chandler Harris', 'image': '/uncle-remus-and-brer-rabbit.png', 'genre': 'Children\'s Literature' },
{ 'id': 564, 'title': 'Household Stories by the Brothers Grimm by Brothers Grimm', 'image': '/household-stories-by-the-brothers-grimm.png', 'genre': 'Children\'s Literature' },
{ 'id': 565, 'title': 'Carmilla by Sheridan Le Fanu', 'image': '/carmilla.png', 'genre': 'Horror & Gothic' },
{ 'id': 566, 'title': 'Frankenstein by Mary Shelley', 'image': '/frankenstein.png', 'genre': 'Horror & Gothic' },
{ 'id': 567, 'title': 'Dracula by Bram Stoker', 'image': '/dracula.png', 'genre': 'Horror & Gothic' },
{ 'id': 568, 'title': 'The Strange Case of Dr Jekyll and Mr Hyde by Robert Louis Stevenson', 'image': '/the-strange-case.png', 'genre': 'Horror & Gothic' },
{ 'id': 569, 'title': 'A Pail of Air by Fritz Leiber', 'image': '/a-pail-of-air.png', 'genre': 'Horror & Gothic' },
{ 'id': 570, 'title': 'Famous Modern Ghost Stories by Various Authors', 'image': '/famous-modern-ghost-stories.png', 'genre': 'Horror & Gothic' },
{ 'id': 571, 'title': 'Ghost Stories of an Antiquary by M. R. James', 'image': '/ghost-stories-of-an-antiquary.png', 'genre': 'Horror & Gothic' },
{ 'id': 572, 'title': 'Frankenstein by Mary Wollstonecraft Shelley', 'image': '/frankenstein.png', 'genre': 'Horror & Gothic' },
{ 'id': 573, 'title': 'Le Corbeau by Edgar Allan Poe', 'image': '/le-corbeau.png', 'genre': 'Horror & Gothic' },
{ 'id': 574, 'title': 'La Saga de Njal by Anonymous', 'image': '/la-saga-de-njal.png', 'genre': 'Horror & Gothic' },
{ 'id': 575, 'title': 'Metamorphosis by Franz Kafka', 'image': '/metamorphosis.png', 'genre': 'Horror & Gothic' },
{ 'id': 576, 'title': 'Tales of Terror and Mystery by Various Authors', 'image': '/tales-of-terror-and-mystery.png', 'genre': 'Horror & Gothic' },
{ 'id': 577, 'title': 'Salomé: A Tragedy in One Act by Oscar Wilde', 'image': '/salome-a-tragedy-in-one-act.png', 'genre': 'Horror & Gothic' },
{ 'id': 578, 'title': 'The Dunwich Horror', 'image': '/the-dunwich-horror.png', 'genre': 'Horror & Gothic' },
{ 'id': 579, 'title': 'The Fall of the House of Usher', 'image': '/the-fall-of-the-house-of-usher.png', 'genre': 'Horror & Gothic' },
{ 'id': 580, 'title': 'The Great God Pan', 'image': '/the-great-god-pan.png', 'genre': 'Horror & Gothic' },
{ 'id': 581, 'title': 'The House on the Borderland by William Hope Hodgson', 'image': '/the-house-on-the-borderland.png', 'genre': 'Horror & Gothic' },
{ 'id': 582, 'title': 'The King in Yellow by Robert W. Chambers', 'image': '/the-king-in-yellow.png', 'genre': 'Horror & Gothic' },
{ 'id': 583, 'title': 'The Monk: A Romance by M. G. Lewis', 'image': '/the-monk-a-romance.png', 'genre': 'Horror & Gothic' },
{ 'id': 584, 'title': 'The Legend of Sleepy Hollow by Washington Irving', 'image': '/the-legend-of-sleepy-hollow.png', 'genre': 'Horror & Gothic' },
{ 'id': 585, 'title': 'The Vampyre; A Tale by John William Polidori', 'image': '/the-vampyre-a-tale.png', 'genre': 'Horror & Gothic' },
{ 'id': 586, 'title': 'The Wendigo by Algernon Blackwood', 'image': '/the-wendigo.png', 'genre': 'Horror & Gothic' },
{ 'id': 587, 'title': 'The Turn of the Screw by Henry James', 'image': '/the-turn-of-the-screw.png', 'genre': 'Horror & Gothic' },
{ 'id': 588, 'title': 'The Yellow Wallpaper by Charlotte Perkins Gilman', 'image': '/the-yellow-wallpaper.png', 'genre': 'Horror & Gothic' },
{ 'id': 589, 'title': 'Uncle Silas: A Tale of Bartram-Haugh by J. Sheridan Le Fanu', 'image': '/uncle-silas-a-tale-of-bartram-haugh.png', 'genre': 'Horror & Gothic' },
{ 'id': 590, 'title': 'Venus in Furs by Leopold von Sacher-Masoch', 'image': '/venus-in-furs.png', 'genre': 'Horror & Gothic' },
{ 'id': 591, 'title': 'Wieland or the Transformation: An American Tale by Charles Brockden Brown', 'image': '/wieland-or-the-transformation-an-american-tale.png', 'genre': 'Horror & Gothic' },
{ 'id': 592, 'title': 'A Modest Proposal by Jonathan Swift', 'image': '/a-modest-proposal.png', 'genre': 'Social & Political' },
{ 'id': 593, 'title': 'A Vindication of the Rights of Woman by Mary Wollstonecraft', 'image': '/a-vindication-of-the-rights-of-woman.png', 'genre': 'Social & Political' },
{ 'id': 594, 'title': 'An Inquiry into the Nature and Causes of the Wealth of Nations by Adam Smith', 'image': '/an-inquiry-into-the-nature-and-causes-of-the-wealth-of-nations.png', 'genre': 'Social & Political' },
{ 'id': 595, 'title': 'An Enquiry Concerning Human Understanding by David Hume', 'image': '/an-enquiry-concerning-human-understanding.png', 'genre': 'Social & Political' },
{ 'id': 596, 'title': 'Against the Grain by Joris-Karl Huysmans', 'image': '/against-the-grain.png', 'genre': 'Social & Political' },
{ 'id': 597, 'title': 'Areopagitica by John Milton', 'image': '/areopagitica.png', 'genre': 'Social & Political' },
{ 'id': 598, 'title': 'Common Sense by Thomas Paine', 'image': '/common-sense.png', 'genre': 'Social & Political' },
{ 'id': 599, 'title': 'Democracy in America Volume 1 by Alexis de Tocqueville', 'image': '/democracy-in-america-volume-1.png', 'genre': 'Social & Political' },
{ 'id': 600, 'title': 'Fundamental Principles of the Metaphysic of Morals by Immanuel Kant', 'image': '/fundamental-principles-of-the-metaphysic-of-morals.png', 'genre': 'Social & Political' },
{ 'id': 601, 'title': 'Der Briefwechsel Zwischen Friedrich Engels und Karl Marx 1844 bis 1883 by Karl Marx & Friedrich Engels', 'image': '/der-briefwechsel-zwischen-friedrich-engels-und-karl-marx-1844-bis-1883.png', 'genre': 'Social & Political' },
{ 'id': 602, 'title': 'Garden Cities of To-Morrow by Ebenezer Howard', 'image': '/garden-cities-of-to-morrow.png', 'genre': 'Social & Political' },
{ 'id': 603, 'title': 'Herland by Charlotte Perkins Gilman', 'image': '/herland.png', 'genre': 'Social & Political' },
{ 'id': 604, 'title': 'Les Conséquences Politiques de la Paix by Leon Trotsky', 'image': '/les-consequences-politiques-de-la-paix.png', 'genre': 'Social & Political' },
{ 'id': 605, 'title': 'Mein Kampf (My Struggle) by Adolf Hitler', 'image': '/mein-kampf-my-struggle.png', 'genre': 'Social & Political' },
{ 'id': 606, 'title': 'My Bondage and My Freedom by Frederick Douglass', 'image': '/my-bondage-and-my-freedom.png', 'genre': 'Social & Political' },
{ 'id': 607, 'title': 'My Further Disillusionment by Emma Goldman', 'image': '/my-further-disillusionment.png', 'genre': 'Social & Political' },
{ 'id': 608, 'title': 'News from Nowhere: Or, an Epoch of Rest by William Morris', 'image': '/news-from-nowhere-or-an-epoch-of-rest.png', 'genre': 'Social & Political' },
{ 'id': 609, 'title': 'Narrative of the Life of Frederick Douglass, an American Slave by Frederick Douglass', 'image': '/narrative-of-the-life-of-frederick-douglass-an-american-slave.png', 'genre': 'Social & Political' },
{ 'id': 610, 'title': 'On Liberty by John Stuart Mill', 'image': '/on-liberty.png', 'genre': 'Social & Political' },
{ 'id': 611, 'title': 'Public Opinion by Walter Lippmann', 'image': '/public-opinion.png', 'genre': 'Social & Political' },
{ 'id': 612, 'title': 'Second Treatise of Government by John Locke', 'image': '/second-treatise-of-government.png', 'genre': 'Social & Political' },
{ 'id': 613, 'title': 'Social Devices for Impelling Women to Bear and Rear Children by Harriet Hardy', 'image': '/social-devices-for-impelling-women-to-bear-and-rear-children.png', 'genre': 'Social & Political' },
{ 'id': 614, 'title': 'The Communist Manifesto', 'image': '/the-communist-manifesto.png', 'genre': 'Social & Political' },
{ 'id': 615, 'title': 'The Crowd: A Study of the Popular Mind', 'image': '/the-crowd-a-study-of-the-popular-mind.png', 'genre': 'Social & Political' },
{ 'id': 616, 'title': 'The Federalist Papers', 'image': '/the-federalist-paper.png', 'genre': 'Social & Political' },
{ 'id': 617, 'title': 'The Jewish State by Theodor Herzl', 'image': '/the-jewish-state.png', 'genre': 'Social & Political' },
{ 'id': 618, 'title': 'The Negro Workers by George Padmore', 'image': '/the-negro-workers.png', 'genre': 'Social & Political' },
{ 'id': 619, 'title': 'The Principles of Masonic Law by Albert G. Mackey', 'image': '/the-principles-of-masonic-law.png', 'genre': 'Social & Political' },
{ 'id': 620, 'title': 'The Principles of Economics with Applications to Practical Problems by Frank A. Fetter', 'image': '/the-principles-of-economics-with-applications-to-practical-problems.png', 'genre': 'Social & Political' },
{ 'id': 621, 'title': 'The Souls of Black Folk by W.E.B. Du Bois', 'image': '/the-souls-of-black-folk.png', 'genre': 'Social & Political' },
{ 'id': 622, 'title': 'The Rising Tide of Color Against White World-Supremacy by Lothrop Stoddard', 'image': '/the-rising-tide-of-color-against-white-world-supremacy.png', 'genre': 'Social & Political' },
{ 'id': 623, 'title': 'The Theory of the Leisure Class by Thorstein Veblen', 'image': '/the-theory-of-the-leisure-class.png', 'genre': 'Social & Political' },
{ 'id': 624, 'title': 'The Witch-Cult in Western Europe: A Study in Anthropology by Margaret Alice Murray', 'image': '/the-witch-cult-in-western-europe-a-study-in-anthropology.png', 'genre': 'Social & Political' },
{ 'id': 625, 'title': 'Utopia by Thomas More', 'image': '/utopia.png', 'genre': 'Social & Political' },
{ 'id': 626, 'title': 'Woman in the Nineteenth Century by Margaret Fuller', 'image': '/woman-in-the-nineteenth-century.png', 'genre': 'Social & Political' },
{ 'id': 627, 'title': 'Incidents in the Life of a Slave Girl by Harriet Ann Jacobs', 'image': '/incidents-in-the-life-of-a-slave-girl.png', 'genre': 'Social & Political' },
{ 'id': 628, 'title': 'An Introduction to Mathematics by Alfred North Whitehead', 'image': '/an-introduction-to-mathematics.png', 'genre': 'Science & Mathematics' },
{ 'id': 629, 'title': 'Calculus Made Easy by Silvanus P. Thompson', 'image': '/calculus-made-easy.png', 'genre': 'Science & Mathematics' },
{ 'id': 630, 'title': 'A Greek-English Lexicon to the New Testament by Joseph Henry Thayer', 'image': '/a-greek-english-lexicon-to-the-new-testament.png', 'genre': 'Science & Mathematics' },
{ 'id': 631, 'title': 'A System of Logic, Ratiocinative and Inductive by John Stuart Mill', 'image': '/a-system-of-logic-ratiocinative-and-inductive.png', 'genre': 'Science & Mathematics' },
{ 'id': 632, 'title': '1001-задача-для-умственного-счета by М. И. Сканави', 'image': '/1001-задача-для-умственного-счета.png', 'genre': 'Science & Mathematics' },
{ 'id': 633, 'title': 'A Dictionary of Slang, Cant, and Vulgar Words by John S. Farmer', 'image': '/a-dictionary-of-slang-cant-and-vulgar-words.png', 'genre': 'Science & Mathematics' },
{ 'id': 634, 'title': 'An Investigation of the Laws of Thought by George Boole', 'image': '/an-investigation-of-the-laws-of-thought.png', 'genre': 'Science & Mathematics' },
{ 'id': 635, 'title': 'Experimental Researches in Electricity, Volume 1 by Michael Faraday', 'image': '/experimental-researches-in-electricity-volume-1.png', 'genre': 'Science & Mathematics' },
{ 'id': 636, 'title': 'Helps to Latin Translation at Sight by Edmund Luce', 'image': '/helps-to-latin-translation-at-sight.png', 'genre': 'Science & Mathematics' },
{ 'id': 637, 'title': 'Little Masterpieces of Science: Mind by Various Authors', 'image': '/little-masterpieces-of-science-mind.png', 'genre': 'Science & Mathematics' },
{ 'id': 638, 'title': 'Philosophiae Naturalis Principia Mathematica by Isaac Newton', 'image': '/philosophiae-naturalis-principia-mathematica.png', 'genre': 'Science & Mathematics' },
{ 'id': 639, 'title': 'Relativity: The Special and General Theory by Albert Einstein', 'image': '/relativity-the-special-and-general-theory.png', 'genre': 'Science & Mathematics' },
{ 'id': 640, 'title': 'The Golden Bough: A Study of Magic and Religion', 'image': '/the-golden-bough-a-study-of-magic-and-religion.png', 'genre': 'Science & Mathematics' },
{ 'id': 641, 'title': 'Beowulf by Anonymous', 'image': '/beowulf-an-anglo-saxon-epic-poem.png', 'genre': 'Mythology & Legends' },
{ 'id': 642, 'title': 'The Iliad by Homer', 'image': '/the-iliad.png', 'genre': 'Mythology & Legends' },
{ 'id': 643, 'title': 'The Odyssey by Homer', 'image': '/the-odyssey.png', 'genre': 'Mythology & Legends' },
{ 'id': 644, 'title': 'The Epic of Gilgamesh by Anonymous', 'image': '/the-epic-of-gilgamesh.png', 'genre': 'Mythology & Legends' },
{ 'id': 645, 'title': 'An Old Babylonian Version of the Gilgamesh Epic by Morris Jastrow', 'image': '/the-epic-of-gilgamesh.png', 'genre': 'Mythology & Legends' },
{ 'id': 646, 'title': 'Ars Amatoria or The Art of Love by Ovid', 'image': '/ars-amatoria-or-the-art-of-love.png', 'genre': 'Mythology & Legends' },
{ 'id': 647, 'title': 'Haida Texts and Myths by John Swanton', 'image': '/haida-texts-and-myths.png', 'genre': 'Mythology & Legends' },
{ 'id': 648, 'title': 'Filipino Popular Tales by Dean S. Fansler', 'image': '/filipino-popular-tales.png', 'genre': 'Mythology & Legends' },
{ 'id': 649, 'title': 'Myths and Legends of China by E. T. C. Werner', 'image': '/myths-and-legends-of-china.png', 'genre': 'Mythology & Legends' },
{ 'id': 650, 'title': 'Old World Japan: Legends of the Land of the Gods by Frank Rinder', 'image': '/old-world-japan-legends-of-the-land-of-the-gods.png', 'genre': 'Mythology & Legends' },
{ 'id': 651, 'title': 'Philippine Folk Tales by Mabel Cook Cole', 'image': '/philippine-folk-tales.png', 'genre': 'Mythology & Legends' },
{ 'id': 652, 'title': 'The Argonautica', 'image': '/the-argonautica.png', 'genre': 'Mythology & Legends' },
{ 'id': 653, 'title': 'The Aeneid', 'image': '/the-aeneid.png', 'genre': 'Mythology & Legends' },
{ 'id': 655, 'title': 'The Mahabharata of Krishna-Dwaipayana Vyasa', 'image': '/the-mahabharata-of-krishna-dwaipayana-vyasa.png', 'genre': 'Mythology & Legends' },
{ 'id': 656, 'title': 'The Metamorphoses of Ovid by Ovid', 'image': '/the-metamorphoses-of-ovid.png', 'genre': 'Mythology & Legends' },
{ 'id': 657, 'title': 'The Mabinogion by Charlotte Guest', 'image': '/the-mabinogion.png', 'genre': 'Mythology & Legends' },
{ 'id': 658, 'title': 'The Ramayan of Valmiki translated into English Verse by Ralph T.H. Griffith', 'image': '/the-ramayan-of-valmiki-translated-into-english-verse.png', 'genre': 'Mythology & Legends' },
{ 'id': 659, 'title': 'The Song Celestial or Bhagavad-Gita (From the Mahâbhârata) by Edwin Arnold', 'image': '/the-song-celestial-or-bhagavad-gita-(from-the-mahabharata).png', 'genre': 'Mythology & Legends' },
{ 'id': 660, 'title': 'The Thousand and One Nights Vol I by Anonymous', 'image': '/the-thousand-and-one-nights-vol-I.png', 'genre': 'Mythology & Legends' },
{ 'id': 661, 'title': 'Hindu Literature: Comprising The Book of Good Counsels, Nala and Damayanti, The Ramayana and Sakuntala', 'image': '/hindu-literature-comprising-the-book-of-good-counsels-nala-and-damayanti-the-ramayana-and-sakoonlata.png', 'genre': 'Mythology & Legends' },
{ 'id': 662, 'title': 'Autobiography of Benjamin Franklin by Benjamin Franklin', 'image': '/autobiography-of-benjamin-franklin.png', 'genre': 'Biography & Autobiography' },
{ 'id': 663, 'title': 'Autobiography of Andrew Carnegie by Andrew Carnegie', 'image': '/autobiography-of-andrew-carnegie.png', 'genre': 'Biography & Autobiography' },
{ 'id': 665, 'title': 'Apologia Pro Vita Sua by John Henry Newman', 'image': '/apologia-pro-vita-sua.png', 'genre': 'Biography & Autobiography' },
{ 'id': 666, 'title': 'Autobiography of a Yogi by Paramahansa Yogananda', 'image': '/autobiography-of-a-yogi.png', 'genre': 'Biography & Autobiography' },
{ 'id': 667, 'title': 'Grace Abounding to the Chief of Sinners by John Bunyan', 'image': '/grace-abounding-to-the-chief-of-sinners.png', 'genre': 'Biography & Autobiography' },
{ 'id': 668, 'title': 'Franklin\'s Way to Wealth or Poor Richard Improved by Benjamin Franklin', 'image': '/franklins-way-to-wealth-or-poor-richard-improved.png', 'genre': 'Biography & Autobiography' },
{ 'id': 669, 'title': 'My Life Volume 1 by Richard Wagner', 'image': '/my-life-volume-1.png', 'genre': 'Biography & Autobiography' },
{ 'id': 670, 'title': 'Narrative of the Life of Frederick Douglass, an American Slave by Frederick Douglass', 'image': '/narrative-of-the-life-of-frederick-douglass-an-american-slave.png', 'genre': 'Biography & Autobiography' },
{ 'id': 671, 'title': 'Memoirs of the Court of Marie Antoinette, Queen of France, Complete by Madame Campan', 'image': '/memoirs-of-the-court-of-marie-antoinette-queen-of-france-complete.png', 'genre': 'Biography & Autobiography' },
{ 'id': 672, 'title': 'Personal Memoirs of U.S. Grant (Complete) by Ulysses S. Grant', 'image': '/personal-memoirs-of-u-s-grant-(complete).png', 'genre': 'Biography & Autobiography' },
{ 'id': 673, 'title': 'Sailing Alone Around the World by Joshua Slocum', 'image': '/sailing-alone-around-the-world.png', 'genre': 'Biography & Autobiography' },
{ 'id': 674, 'title': 'The Confessions of Jean-Jacques Rousseau', 'image': '/the-confessions-of-jean-jacques-rousseau-complete.png', 'genre': 'Biography & Autobiography' },
{ 'id': 675, 'title': 'The Education of Henry Adams', 'image': '/the-education-of-henry-adams.png', 'genre': 'Biography & Autobiography' },
{ 'id': 676, 'title': 'The Memoirs of Jacques Casanova de Seingalt, 1725-1798 Complete by Jacques Casanova de Seingalt', 'image': '/the-memoirs-of-jacques-casanova-de-seingalt-1725-1798-complete.png', 'genre': 'Biography & Autobiography' },
{ 'id': 677, 'title': 'The Notebooks of Leonardo Da Vinci Complete by Leonardo Da Vinci', 'image': '/the-notebooks-of-leonardo-da-vinci-complete.png', 'genre': 'Biography & Autobiography' },
{ 'id': 678, 'title': 'The Papers and Writings of Abraham Lincoln Complete by Abraham Lincoln', 'image': '/the-papers-and-writings-of-abraham-lincoln-complete.png', 'genre': 'Biography & Autobiography' },
{ 'id': 679, 'title': 'The Story of My Life: Complete by Helen Keller', 'image': '/the-story-of-my-life-complete.png', 'genre': 'Biography & Autobiography' },
{ 'id': 680, 'title': 'The Underground Railroad by William Still', 'image': '/the-underground-railroad.png', 'genre': 'Biography & Autobiography' },
{ 'id': 681, 'title': 'The Worst Journey in the World, Antarctic 1910-1913 by Apsley Cherry-Garrard', 'image': '/the-worst-journey-in-the-world-antarctic-1910-1913.png', 'genre': 'Biography & Autobiography' },
{ 'id': 682, 'title': 'Incidents in the Life of a Slave Girl by Harriet Ann Jacobs', 'image': '/incidents-in-the-life-of-a-slave-girl.png', 'genre': 'Biography & Autobiography' },
{ 'id': 683, 'title': 'A Modest Proposal by Jonathan Swift', 'image': '/a-modest-proposal.png', 'genre': 'Satire' },
{ 'id': 684, 'title': 'A Tale of a Tub by Jonathan Swift', 'image': '/a-tale-of-a-tub.png', 'genre': 'Satire' },
{ 'id': 685, 'title': 'Candide by Voltaire', 'image': '/candide.png', 'genre': 'Satire' },
{ 'id': 688, 'title': 'Gulliver\'s Travels by Jonathan Swift', 'image': '/gullivers-travels.png', 'genre': 'Satire' },
{ 'id': 689, 'title': 'Gargantua and Pantagruel by François Rabelais', 'image': '/gargantua-and-pantagruel.png', 'genre': 'Satire' },
{ 'id': 690, 'title': 'Erewhon, or Over the Range by Samuel Butler', 'image': '/erewhon-or-over-the-range.png', 'genre': 'Satire' },
{ 'id': 691, 'title': 'Micromegas by Voltaire', 'image': '/micromegas.png', 'genre': 'Satire' },
{ 'id': 692, 'title': 'Sketches New and Old by Mark Twain', 'image': '/sketches-new-and-old.png', 'genre': 'Satire' },
{ 'id': 693, 'title': 'Sult by Knut Hamsun', 'image': '/sult.png', 'genre': 'Satire' },
{ 'id': 694, 'title': 'The Battle of the Books and Other Short Pieces', 'image': '/the-battle-of-the-books-and-other-short-pieces.png', 'genre': 'Satire' },
{ 'id': 695, 'title': 'The Devil\'s Dictionary', 'image': '/the-devils-dictionary.png', 'genre': 'Satire' },
{ 'id': 696, 'title': 'The Man That Corrupted Hadleyburg by Mark Twain', 'image': '/the-man-that-corrupted-hadleyburg.png', 'genre': 'Satire' },
{ 'id': 697, 'title': 'The Inspector-General by Nikolai Gogol', 'image': '/the-inspector-general.png', 'genre': 'Satire' },
{ 'id': 698, 'title': 'The School for Scandal by Richard Brinsley Sheridan', 'image': '/the-school-for-scandal.png', 'genre': 'Satire' },
{ 'id': 699, 'title': 'In Praise of Folly by Desiderius Erasmus', 'image': '/in-praise-of-folly.png', 'genre': 'Satire' },
{ 'id': 700, 'title': 'Tremendous Trifles by G. K. Chesterton', 'image': '/tremendous-trifles.png', 'genre': 'Satire' },
{ 'id': 701, 'title': 'The City of God Volume II', 'image': '/the-city-of-god-volume-ii.png', 'genre': 'Religion & Spirituality' },
{ 'id': 702, 'title': 'The Enchiridion', 'image': '/the-enchiridion.png', 'genre': 'Religion & Spirituality' },
{ 'id': 703, 'title': 'The Divine Comedy', 'image': '/the-divine-comedy.png', 'genre': 'Religion & Spirituality' },
{ 'id': 704, 'title': 'The Imitation of Christ by Thomas à Kempis', 'image': '/the-imitation-of-christ.png', 'genre': 'Religion & Spirituality' },
{ 'id': 705, 'title': 'The King James Version of the Bible', 'image': '/the-king-james-version-of-the-bible.png', 'genre': 'Religion & Spirituality' },
{ 'id': 706, 'title': 'The Kama Sutra', 'image': '/the-kama-sutra.png', 'genre': 'Religion & Spirituality' },
{ 'id': 707, 'title': 'The Person and Work of the Holy Spirit by R. A. Torrey', 'image': '/the-person-and-work-of-the-holy-spirit.png', 'genre': 'Religion & Spirituality' },
{ 'id': 708, 'title': 'The Tao-Teh-King or The Tao and Its Characteristics by Laozi', 'image': '/the-tao-teh-king-or-the-tao-and-its-characteristics.png', 'genre': 'Religion & Spirituality' },
{ 'id': 710, 'title': 'Thought-Forms by Annie Besant and C. W. Leadbeater', 'image': '/thought-forms.png', 'genre': 'Religion & Spirituality' },
{ 'id': 711, 'title': 'The Koran (Al-Qur\'an)', 'image': '/the-koran.png', 'genre': 'Religion & Spirituality' },
{ 'id': 712, 'title': 'Institutes of the Christian Religion (Vol. 1 of 2) by John Calvin', 'image': '/institutes-of-the-christian-religion-(vol.-1-of-2).png', 'genre': 'Religion & Spirituality' }
      ],
      searchQuery: '',
      genres: [
        'Classics', 'Science Fiction & Fantasy', 'Mystery & Thriller', 'Philosophy',
        'Drama & Plays', 'Historical', 'Non-Fiction', 'Adventure', 'Romance', 
        'Poetry', 'Children\'s Literature', 'Horror & Gothic', 'Social & Political',
        'Science & Mathematics', 'Mythology & Legends', 'Biography & Autobiography', 
        'Satire', 'Religion & Spirituality'
      ],
    };
  },
  computed: {
    filteredBooks() {
      const query = this.searchQuery.toLowerCase();
      return this.books.filter((book) =>
        book.title.toLowerCase().includes(query) &&
        (!this.selectedGenre || book.genre === this.selectedGenre)
      );
    },
    selectedGenre() {
      return this.getSelectedBookGenre;
    },
    ...mapGetters(['getScrollPosition', 'getBookSearchQuery', 'getSelectedBookGenre']),
  },
  methods: {
    ...mapActions(['saveScrollPosition', 'saveBookSearchQuery', 'saveSelectedBookGenre']),
    goBackToResources() {
      this.$router.push('/resources');
    },
    goToBookDetails(bookId) {
      this.$router.push({ name: 'book-details', params: { bookId } });
    },
    saveScrollAndQuery() {
      this.saveScrollPosition({
        pageId: 'BooksPage',
        position: this.$refs.content.scrollTop,
      });
      this.saveBookSearchQuery(this.searchQuery);
      this.saveSelectedBookGenre(this.selectedGenre); // Save the selected genre
    },
    filterByGenre(genre) {
      this.saveScrollAndQuery(); // Save scroll position, search query, and genre
      this.saveSelectedBookGenre(genre);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.searchQuery = this.getBookSearchQuery;
      this.saveSelectedBookGenre(this.selectedGenre); // Set the selected genre
      const savedPosition = this.getScrollPosition('BooksPage');
      this.$refs.content.scrollTop = savedPosition;
    });
  },
  beforeRouteLeave(to, from, next) {
    this.saveScrollAndQuery();
    next();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
}

.content {
  margin-top: 60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

/* Genres Container */
.genres-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.genre-button {
  margin: 5px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: white;
  cursor: pointer;
}

.genre-button:hover {
  background-color: #555;
}

.books-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.book-item {
  margin: 10px;
  text-align: center;
  cursor: pointer;
  width: 150px; /* Match the image width */
}

.book-image {
  width: 150px;
  height: 200px;
  object-fit: cover;
}

.book-item p {
  width: 150px; /* Ensure the title has the same width as the image */
  word-wrap: break-word; /* Allows long titles to wrap onto the next line */
  margin-top: 10px;
  font-size: 14px; /* Adjust this if you want larger/smaller text */
}

input[type='text'] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
}
</style>

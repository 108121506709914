<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Back to Private Topics" 
      :showLogoutButton="true" 
      @back="goBackToPrivateTopics"
    />
    <div class="content">
      <h1>Private Suggestions for {{ formattedTopicName }}</h1>
      <div class="new-suggestion-container">
        <textarea v-model="newSuggestion" placeholder="Write your suggestion..." maxlength="5000"></textarea>
        <button @click="addSuggestion" class="submit-button">Submit</button>
      </div>
      <div class="sorting-buttons">
        <button @click="sortByMostRecent">Most Recent</button>
        <button @click="sortByMostVoted">Most Voted</button>
      </div>
      <ul class="suggestions-list">
        <li v-for="suggestion in sortedSuggestions" :key="suggestion._id" class="suggestion-item">
          <div class="suggestion-box">
            <div class="suggestion-details">
              <p><strong>{{ suggestion.username }}</strong></p>
              <p><strong>Submitted:</strong> {{ new Date(suggestion.timestamp).toLocaleString() }}</p>
              <p v-if="suggestion.editedTimestamp"><strong>Edited:</strong> {{ new Date(suggestion.editedTimestamp).toLocaleString() }}</p>
              <p><strong>Resources:</strong> {{ suggestion.resource || 'No resource' }}</p>
              <div class="suggestion-text">
                <p v-if="editingId !== suggestion._id">{{ suggestion.text }}</p>
                <textarea v-else v-model="editingText" maxlength="5000"></textarea>
              </div>
              <div class="actions">
                <button v-if="!hasVoted(suggestion)" @click="vote(suggestion)">Vote ({{ suggestion.votes || 0 }})</button>
                <button v-if="hasVoted(suggestion)" @click="removeVote(suggestion)">Remove Vote ({{ suggestion.votes || 0 }})</button>
                <button v-if="editingId !== suggestion._id && suggestion.username === currentUser" @click="startEditing(suggestion)">Edit</button>
                <button v-if="suggestion.username === currentUser" @click="deleteSuggestion(suggestion._id)">Delete</button>
                <div v-if="editingId === suggestion._id">
                  <button @click="saveEdit(suggestion._id)">Save</button>
                  <button @click="cancelEdit">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/axiosConfig';
import Header from './Header.vue';

export default {
  components: { Header },
  props: {
    topicName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newSuggestion: '',
      suggestions: [],
      editingId: null,
      editingText: '',
      sortOption: 'mostRecent',
      currentUser: JSON.parse(localStorage.getItem('currentUser')).username || 'guest',
    };
  },
  computed: {
    sortedSuggestions() {
      if (this.sortOption === 'mostVoted') {
        return [...this.suggestions].sort((a, b) => b.votes - a.votes);
      }
      return [...this.suggestions].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    },
    formattedTopicName() {
      return this.topicName;
    },
  },
  async created() {
    await this.loadSuggestions();
  },
  methods: {
    async loadSuggestions() {
      try {
        const response = await axiosInstance.get(`/private-suggestions/${this.topicName}`);
        this.suggestions = Array.isArray(response.data) ? response.data : [];
      } catch (error) {
        console.error('Failed to load suggestions:', error);
      }
    },
    async addSuggestion() {
      if (!this.newSuggestion.trim()) return;

      const newSuggestion = {
        topicID: this.topicName,
        username: this.currentUser,
        text: this.newSuggestion.trim(),
        resource: localStorage.getItem('resource') || 'No resource',
      };

      try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
          this.errorMessage = 'Login required to submit a suggestion.';
          return;
        }

        const response = await axiosInstance.post('/private-suggestions', newSuggestion);

        if (response.data.success) {
          this.suggestions.unshift(response.data.suggestion);  // Immediately update the suggestions list
          this.newSuggestion = '';
        } else {
          this.errorMessage = response.data.message;
        }
      } catch (error) {
        console.error('Failed to add suggestion:', error);
      }
    },
    async deleteSuggestion(id) {
      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.delete(`/private-suggestions/${id}`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        this.suggestions = this.suggestions.filter(suggestion => suggestion._id !== id);
      } catch (error) {
        console.error('Failed to delete suggestion:', error);
      }
    },
    startEditing(suggestion) {
      this.editingId = suggestion._id;
      this.editingText = suggestion.text;
    },
    async saveEdit(id) {
      if (!this.editingText.trim()) return;

      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.put(`/private-suggestions/${id}`, { text: this.editingText.trim(), editedTimestamp: Date.now() });
        this.editingId = null;
        this.editingText = '';
        await this.loadSuggestions();
      } catch (error) {
        console.error('Failed to save edit', error);
      }
    },
    cancelEdit() {
      this.editingId = null;
      this.editingText = '';
    },
    async vote(suggestion) {
      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.put(`/private-suggestions/${suggestion._id}/vote`, { username: this.currentUser });
        await this.loadSuggestions();
      } catch (error) {
        console.error('Failed to vote:', error);
      }
    },
    async removeVote(suggestion) {
      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.put(`/private-suggestions/${suggestion._id}/remove-vote`, { username: this.currentUser });
        await this.loadSuggestions();
      } catch (error) {
        console.error('Failed to remove vote:', error);
      }
    },
    hasVoted(suggestion) {
      return suggestion.voters.includes(this.currentUser);
    },
    sortByMostRecent() {
      this.sortOption = 'mostRecent';
    },
    sortByMostVoted() {
      this.sortOption = 'mostVoted';
    },
    goBackToPrivateTopics() {
      this.$router.push('/private-topics');
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 60px;
  width: 100%;
  padding: 20px;
}

.new-suggestion-container {
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
}

textarea {
  width: 100%;
  max-width: 800px;
  height: 150px;
  padding: 10px;
  box-sizing: border-box;
}

.submit-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  max-width: 800px;
}

.submit-button:hover {
  background-color: #555;
}

.suggestions-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
  max-width: 900px;
}

.suggestion-item {
  margin: 10px 0;
}

.suggestion-box {
  border: 1px solid #ccc;
  padding: 10px;
}

.suggestion-details {
  text-align: left;
}

.suggestion-text {
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.actions {
  margin-top: 5px;
}

.sorting-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.sorting-buttons button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin: 0 10px;
}

.sorting-buttons button:hover {
  background-color: #555;
}
</style>

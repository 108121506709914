<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Back to Previous Page" 
      :showLogoutButton="true" 
      @back="goBackToPreviousPage" 
    />
    <div class="content">
      <h1 class="title">Theodor N. Engøy</h1>
      <img src="/theodor-engoy.png" alt="Theodor N. Engoy" class="founder-image">
      <h2>Founder of Resuvonia</h2>
      <h3>Contact Resuvonia</h3>
      <a href="mailto:resuvonia@gmail.com" class="contact-logo">
        <img src="/gmail-logo.png" alt="Gmail" class="social-logo">
      </a>
      <h3>Resuvonia’s Social Media</h3>
      <div class="social-media-links">
        <a href="https://www.youtube.com/@Resuvonia" target="_blank">
          <img src="/youtube-logo.png" alt="YouTube" class="social-logo">
        </a>
        <a href="https://www.tiktok.com/@resuvonia" target="_blank">
          <img src="/tiktok-logo.png" alt="TikTok" class="social-logo">
        </a>
        <a href="https://www.instagram.com/resuvonia/" target="_blank">
          <img src="/instagram-logo.png" alt="Instagram" class="social-logo">
        </a>
        <a href="https://x.com/Resuvonia" target="_blank">
          <img src="/x-logo.png" alt="Twitter/X" class="social-logo">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: {
    Header,
  },
  methods: {
    goBackToPreviousPage() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  margin-top: 60px;
}

.title {
  margin-bottom: 20px;
}

.founder-image {
  max-width: 200px;
  margin-bottom: 20px;
}

.social-media-links {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-logo {
  width: 50px;
}

.contact-logo {
  margin-bottom: 20px;
}
</style>
